import EditIcon from "@mui/icons-material/Edit";
import { actions } from "../../../../redux/store/store";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import ConfirmDialogModal from "../confirmDialog";
import { useUpdateDriverMutation } from "../../../../api/drivers";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import { checkForError } from "../../../../constants/extras/errorHandlers";

const EditOAIInsurance = ({ state, title, id, currentUser, driverData }) => {
  const role = currentUser && currentUser.role;
  const [updateDriver, { isLoading: isUpdatingDriver }] = useUpdateDriverMutation();

  const handleDelete = async () => {
    const result = await updateDriver({
      _id: id,
      onboardingDriverOAIFillStatus: null,
      OAI: {
        isOwnOAI: null,
        img: null,
        policy_no: null,
        exp_date: null
      }
    });
    if (!result?.error) {
      sendSuccessMessage(`Insurance Policy updated successfully`);
      actions.refetch.setIsDataRefetch(true);
    } else {
      checkForError(result);
    }
  }

  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{driverData?.OAI?.exp_date}</span>
        {role === "root" &&
          <span
            style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "10px" }}
          >
            <EditIcon
              style={{ width: "20px", height: "20px" }}
              onClick={() =>
                actions.modal.openOAIInsurance({
                  id,
                  title,
                  state,
                  driverData
                })
              } />
            <DeleteIcon onClick={() => setShowDialog(true)} sx={{ color: 'red' }} />
            <button
              className="btn btn_block btn_accent customized-details-btn w-auto"
              onClick={() => actions.modal.openOAILogs(id)}
              type="button"
            >
              Logs
            </button>
          </span>
        }
      </div>

      {showDialog &&
        <ConfirmDialogModal
          title="Delete Occupational Accident Insurance"
          bodyText="Are you sure you want to delete?"
          isOpen
          isLoading={isUpdatingDriver}
          confirmAction={handleDelete}
          onClose={() => setShowDialog(false)}
        />
      }
    </>
  );
};

export default EditOAIInsurance;