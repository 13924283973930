export default {

  'Acura': [
    'CL',
    'CSX',
    'EL',
    'ILX',
    'Integra',
    'Legend',
    'MDX',
    'NSX',
    'RDX',
    'RL',
    'RLX',
    'RSX',
    'SLX',
    'TL',
    'TLX',
    'TSX',
    'ZDX'
  ],
  'Audi': [
    '100',
    '200',
    '50',
    '80',
    '90',
    '920',
    'A1',
    'A2',
    'A3',
    'A4',
    'A4 allroad',
    'A5',
    'A6',
    'A6 allroad',
    'A7',
    'A8',
    'Cabriolet',
    'Coupe',
    'e-tron',
    'e-tron GT',
    'e-tron S',
    'e-tron S Sportback',
    'e-tron Sportback',
    'NSU RO 80',
    'Q2',
    'Q3',
    'Q3 Sportback',
    'Q4 e-tron',
    'Q4 Sportback e-tron',
    'Q5',
    'Q5 Sportback',
    'Q7',
    'Q8',
    'Quattro',
    'R8',
    'R8 LMP',
    'RS 2',
    'RS 3',
    'RS 4',
    'RS 5',
    'RS 6',
    'RS 7',
    'RS e-tron GT',
    'RS Q3',
    'RS Q3 Sportback',
    'RS Q8',
    'S1',
    'S2',
    'S3',
    'S4',
    'S5',
    'S6',
    'S7',
    'S8',
    'SQ2',
    'SQ5',
    'SQ5 Sportback',
    'SQ7',
    'SQ8',
    'TT',
    'TT RS',
    'TTS',
    'Typ R',
    'V8'
  ],
  'BMW': [
    '02 (E10)',
    '1',
    '1M',
    '2',
    '2 Active Tourer',
    '2 Gran Tourer',
    '2000 C/CS',
    '3',
    '3/15',
    '315',
    '3200',
    '321',
    '326',
    '327',
    '340',
    '4',
    '5',
    '501',
    '502',
    '503',
    '507',
    '6',
    '600',
    '7',
    '700',
    '8',
    'E3',
    'E9',
    'i3',
    'i4',
    'i8',
    'iX',
    'iX3',
    'M2',
    'M3',
    'M4',
    'M5',
    'M6',
    'M8',
    'X1',
    'X2',
    'X3',
    'X3 M',
    'X4',
    'X4 M',
    'X5',
    'X5 M',
    'X6',
    'X6 M',
    'X7',
    'Z1',
    'Z3',
    'Z3 M',
    'Z4',
    'Z4 M',
    'Z8'
  ],
  'Buick': [
    'Cascada',
    'Century',
    'Electra',
    'Enclave',
    'Encore',
    'Envision',
    'Estate Wagon',
    'Excelle',
    'GL8',
    'GS',
    'LaCrosse',
    'LeSabre',
    'Limited',
    'Lucerne',
    'Park Avenue',
    'Rainer',
    'Reatta',
    'Regal',
    'Rendezvous',
    'Riviera',
    'Roadmaster',
    'Skyhawk',
    'Skylark',
    'Special',
    'Super',
    'Terraza',
    'Verano',
    'Wildcat'
  ],
  'Cadillac': [
    'Allante',
    'ATS',
    'ATS-V',
    'BLS',
    'Brougham',
    'Catera',
    'CT4',
    'CT4-V',
    'CT5',
    'CT5-V',
    'CT6',
    'CTS',
    'CTS-V',
    'DeVille',
    'DTS',
    'Eldorado',
    'ELR',
    'Escalade',
    'Fleetwood',
    'LSE',
    'Series 62',
    'Seville',
    'Sixty Special',
    'SRX',
    'STS',
    'XLR',
    'XT4',
    'XT5',
    'XT6',
    'XTS'
  ],
  'Chevrolet': [
    '3000-Series',
    'Alero',
    'Apache',
    'Astra',
    'Astro',
    'Avalanche',
    'Aveo',
    'Bel Air',
    'Beretta',
    'Blazer',
    'Blazer K5',
    'Bolt',
    'C-10',
    'C/K',
    'Camaro',
    'Caprice',
    'Captiva',
    'Captiva Sport',
    'Cavalier',
    'Celebrity',
    'Celta',
    'Chevelle',
    'Chevette',
    'Citation',
    'Cobalt',
    'Colorado',
    'Corsa',
    'Corsica',
    'Corvair',
    'Corvette',
    'Cruze',
    'Cruze (HR)',
    'CSV CR8',
    'Deluxe',
    'El Camino',
    'Epica',
    'Equinox',
    'Evanda',
    'Express',
    'Fleetmaster',
    'HHR',
    'Impala',
    'Kalos',
    'Lacetti',
    'Lanos',
    'Lumina',
    'Lumina APV',
    'LUV D-MAX',
    'Malibu',
    'Master',
    'Matiz',
    'Metro',
    'Monte Carlo',
    'Monza',
    'MW',
    'Nexia',
    'Niva',
    'Nova',
    'Nubira',
    'Omega',
    'Orlando',
    'Prizm',
    'Rezzo',
    'S-10 Pickup',
    'Sail',
    'Silverado',
    'Sonic',
    'Spark',
    'Special DeLuxe',
    'Spin',
    'SS',
    'SSR',
    'Starcraft',
    'Suburban',
    'Tacuma',
    'Tahoe',
    'Tavera',
    'Tracker',
    'TrailBlazer',
    'Trans Sport',
    'Traverse',
    'Trax',
    'Uplander',
    'Van',
    'Vectra',
    'Venture',
    'Viva',
    'Volt',
    'Zafira'
  ],
  'Chrysler': [
    '180',
    '200',
    '300',
    '300 Letter Series',
    '300C',
    '300M',
    'Aspen',
    'Cirrus',
    'Concorde',
    'Cordoba',
    'Crossfire',
    'Daytona',
    'Dynasty',
    'ES',
    'Fifth Avenue',
    'Imperial',
    'Imperial Crown',
    'Intrepid',
    'LeBaron',
    'LHS',
    'Nassau',
    'Neon',
    'New Yorker',
    'Newport',
    'Pacifica',
    'Prowler',
    'PT Cruiser',
    'Saratoga',
    'Sebring',
    'Six',
    'Stratus',
    'TC by Maserati',
    'Town & Country',
    'Viper',
    'Vision',
    'Voyager',
    'Windsor'
  ],
  'Dodge': [
    '600',
    'Aries',
    'Avenger',
    'Caliber',
    'Caravan',
    'Challenger',
    'Charger',
    'Charger Daytona',
    'Colt',
    'Custom Royal',
    'D/W Series',
    'D8',
    'Dakota',
    'Dart',
    'Daytona',
    'Diplomat',
    'Durango',
    'Dynasty',
    'Intrepid',
    'Journey',
    'Lancer',
    'Magnum',
    'Mayfair',
    'Monaco',
    'Neon',
    'Nitro',
    'Omni',
    'Polara',
    'Raider',
    'RAM',
    'RAM Van',
    'Ramcharger',
    'Shadow',
    'Spirit',
    'Stealth',
    'Stratus',
    'Viper',
    'WC series'
  ],
  'Fiat': [
    '124',
    '124 Spider',
    '124 Sport Spider',
    '125',
    '126',
    '127',
    '128',
    '130',
    '131',
    '132',
    '2300',
    '238',
    '500',
    '500L',
    '500X',
    '508',
    '600',
    '900T',
    'Albea',
    'Argenta',
    'Barchetta',
    'Brava',
    'Bravo',
    'Cinquecento',
    'Coupe',
    'Croma',
    'Doblo',
    'Duna',
    'Fiorino',
    'Freemont',
    'Fullback',
    'Idea',
    'Linea',
    'Marea',
    'Multipla',
    'Palio',
    'Panda',
    'Punto',
    'Qubo',
    'Regata',
    'Ritmo',
    'Scudo',
    'Sedici',
    'Seicento',
    'Siena',
    'Stilo',
    'Strada',
    'Tempra',
    'Tipo',
    'Ulysse',
    'Uno',
    'X 1/9'
  ],
  'Ford': [
    'Aerostar',
    'Aspire',
    'B-MAX',
    'Bronco',
    'Bronco Sport',
    'Bronco-II',
    'C-MAX',
    'Capri',
    'Consul',
    'Contour',
    'Cortina',
    'Cougar',
    'Country Squire',
    'Crown Victoria',
    'Custom',
    'Econoline',
    'EcoSport',
    'Edge',
    'Escape',
    'Escort',
    'Escort (North America)',
    'Everest',
    'Excursion',
    'Expedition',
    'Explorer',
    'Explorer Sport Trac',
    'F-150',
    'F-2',
    'Fairlane',
    'Fairmont',
    'Falcon',
    'Festiva',
    'Fiesta',
    'Fiesta ST',
    'Five Hundred',
    'Flex',
    'Focus',
    'Focus RS',
    'Focus ST',
    'Freda',
    'Freestar',
    'Freestyle',
    'Fusion',
    'Fusion (North America)',
    'Galaxie',
    'Galaxy',
    'GPA',
    'Granada',
    'Granada (North America)',
    'GT',
    'GT40',
    'Ikon',
    'Ixion',
    'KA',
    'Kuga',
    'Laser',
    'LTD Country Squire',
    'LTD Crown Victoria',
    'M151',
    'Mainline',
    'Maverick',
    'Model A',
    'Model T',
    'Mondeo',
    'Mondeo ST',
    'Mustang',
    'Mustang Mach-E',
    'Orion',
    'Probe',
    'Puma',
    'Puma ST',
    'Ranchero',
    'Ranger',
    'Ranger (North America)',
    'S-MAX',
    'Scorpio',
    'Sierra',
    'Spectron',
    'Taunus',
    'Taurus',
    'Taurus X',
    'Telstar',
    'Tempo',
    'Territory',
    'Thunderbird',
    'Torino',
    'Tourneo Connect',
    'Tourneo Courier',
    'Tourneo Custom',
    'Transit Connect',
    'V8',
    'Windstar',
    'Zephyr'
  ],
  'Genesis': [
    'G70',
    'G80',
    'G90',
    'GV70',
    'GV80'
  ],
  'Geo': [
    'Metro',
    'Prizm',
    'Spectrum',
    'Storm',
    'Tracker'
  ],
  'GMC': [
    '100',
    'Acadia',
    'Canyon',
    'Envoy',
    'Jimmy',
    'Safari',
    'Savana',
    'Sierra',
    'Sonoma',
    'Suburban',
    'Syclone',
    'Terrain',
    'Typhoon',
    'Vandura',
    'Yukon'
  ],
  'Honda': [
    '145',
    'Accord',
    'Acty',
    'Airwave',
    'Ascot',
    'Ascot Innova',
    'Avancier',
    'Ballade',
    'Beat',
    'Brio',
    'Capa',
    'City',
    'Civic',
    'Civic Ferio',
    'Civic Type R',
    'Clarity',
    'Concerto',
    'CR-V',
    'CR-X',
    'CR-Z',
    'Crossroad',
    'Crosstour',
    'Domani',
    'e',
    'Edix',
    'Element',
    'Elysion',
    'Fit',
    'Fit Aria',
    'Fit Shuttle',
    'FR-V',
    'Freed',
    'Grace',
    'Horizon',
    'HR-V',
    'Insight',
    'Inspire',
    'Integra',
    'Integra SJ',
    'Jade',
    'Jazz',
    'Lagreat',
    'Legend',
    'Life',
    'Logo',
    'MDX',
    'Mobilio',
    'Mobilio Spike',
    'N-BOX',
    'N-BOX Slash',
    'N-One',
    'N-WGN',
    'N360',
    'NSX',
    'Odyssey',
    'Odyssey (North America)',
    'Orthia',
    'Partner',
    'Passport',
    'Pilot',
    'Prelude',
    'Quint',
    'Rafaga',
    'Ridgeline',
    'S-MX',
    'S2000',
    'S500',
    'S600',
    'S660',
    'Saber',
    'Shuttle',
    'Stepwgn',
    'Stream',
    'Street',
    "That'S",
    'Today',
    'Torneo',
    'Vamos',
    'Vezel',
    'Vigor',
    'Z',
    'Zest'
  ],
  'Hyundai': [
    'Accent',
    'Aslan',
    'Atos',
    'Avante',
    'Azera',
    'Bayon',
    'Centennial',
    'Click',
    'Coupe',
    'Creta',
    'Dynasty',
    'Elantra',
    'Entourage',
    'EON',
    'Equus',
    'Excel',
    'Galloper',
    'Genesis',
    'Genesis Coupe',
    'Getz',
    'Grace',
    'Grand Starex',
    'Grandeur',
    'H-1',
    'H200',
    'i10',
    'i20',
    'i20 N',
    'i30',
    'i30 N',
    'i40',
    'IONIQ',
    'IONIQ 5',
    'ix20',
    'ix35',
    'ix55',
    'Kona',
    'Lantra',
    'Lavita',
    'Marcia',
    'Matrix',
    'Maxcruz',
    'Palisade',
    'Pony',
    'Santa Fe',
    'Santamo',
    'Scoupe',
    'Solaris',
    'Sonata',
    'Starex',
    'Staria',
    'Stellar',
    'Terracan',
    'Tiburon',
    'Trajet',
    'Tucson',
    'Tuscani',
    'Veloster',
    'Venue',
    'Veracruz',
    'Verna',
    'XG'
  ],
  'Infiniti': [
    'EX',
    'FX',
    'G',
    'I',
    'J',
    'JX',
    'M',
    'Q',
    'Q30',
    'Q40',
    'Q50',
    'Q60',
    'Q70',
    'QX30',
    'QX4',
    'QX50',
    'QX55',
    'QX56',
    'QX60',
    'QX70',
    'QX80'
  ],
  'Isuzu': [
    '117',
    'Amigo',
    'Ascender',
    'Aska',
    'Axiom',
    'Bellett',
    'Bighorn',
    'D-Max',
    'Fargo',
    'Fargo Filly',
    'Florian',
    'Gemini',
    'Hombre',
    'Impulse',
    'KB',
    'Midi',
    'MU',
    'MU-7',
    'MU-X',
    'Oasis',
    'Piazza',
    'Rodeo',
    'Stylus',
    'TF (Pickup)',
    'Trooper',
    'VehiCross',
    'Wizard'
  ],
  'Jaguar': [
    'E-Pace',
    'E-type',
    'F-Pace',
    'F-Type',
    'I-Pace',
    'Mark 2',
    'Mark IX',
    'S-Type',
    'X-Type',
    'XE',
    'XF',
    'XFR',
    'XJ',
    'XJ220',
    'XJR',
    'XJS',
    'XK',
    'XKR'
  ],
  'Jeep': [
    'Cherokee',
    'CJ',
    'Commander',
    'Compass',
    'Gladiator',
    'Grand Cherokee',
    'Liberty (North America)',
    'Liberty (Patriot)',
    'Renegade',
    'Wagoneer',
    'Wrangler'
  ],
  'Kia': [
    'Avella',
    'Borrego',
    'Cadenza',
    'Capital',
    'Carens',
    'Carnival',
    'Carstar',
    'Ceed',
    'Ceed GT',
    'Cerato',
    'Clarus',
    'Concord',
    'Elan',
    'Enterprise',
    'EV6',
    'Forte',
    'Joice',
    'K3',
    'K5',
    'K7',
    'K9',
    'K900',
    'Lotze',
    'Magentis',
    'Mentor',
    'Mohave',
    'Morning',
    'Niro',
    'Opirus',
    'Optima',
    'Picanto',
    'Potentia',
    'Pregio',
    'Pride',
    'Proceed',
    'Quanlima',
    'Quoris',
    'Ray',
    'Retona',
    'Rio',
    'Sedona',
    'Seltos',
    'Sephia',
    'Shuma',
    'Sorento',
    'Soul',
    'Soul EV',
    'Spectra',
    'Sportage',
    'Stinger',
    'Stonic',
    'Telluride',
    'Towner',
    'Venga',
    'Visto',
    'X-Trek',
    'XCeed'
  ],
  'Land Rover': [
    'Defender',
    'Discovery',
    'Discovery Sport',
    'Freelander',
    'Range Rover',
    'Range Rover Evoque',
    'Range Rover Sport',
    'Range Rover Velar',
    'Series I',
    'Series II',
    'Series III'
  ],
  'Lexus': [
    'CT',
    'ES',
    'GS',
    'GS F',
    'GX',
    'HS',
    'IS',
    'IS F',
    'LC',
    'LFA',
    'LM',
    'LS',
    'LX',
    'NX',
    'RC',
    'RC F',
    'RX',
    'SC',
    'UX'
  ],
  'Lincoln': [
    'Aviator',
    'Blackwood',
    'Capri',
    'Continental',
    'Corsair',
    'LS',
    'Mark III',
    'Mark IV',
    'Mark LT',
    'Mark VII',
    'Mark VIII',
    'MKC',
    'MKS',
    'MKT',
    'MKX',
    'MKZ',
    'Nautilus',
    'Navigator',
    'Premiere',
    'Town Car',
    'Versailles',
    'Zephyr'
  ],
  'Mazda': [
    '1000',
    '121',
    '1300',
    '2',
    '3',
    '3 MPS',
    '323',
    '5',
    '6',
    '6 MPS',
    '616',
    '626',
    '818',
    '929',
    'Atenza',
    'Autozam AZ-3',
    'Axela',
    'AZ-1',
    'AZ-Offroad',
    'AZ-Wagon',
    'B-series',
    'Biante',
    'Bongo',
    'Bongo Friendee',
    'BT-50',
    'Capella',
    'Carol',
    'Chantez',
    'Cosmo',
    'Cronos',
    'CX-3',
    'CX-30',
    'CX-5',
    'CX-7',
    'CX-8',
    'CX-9',
    'Demio',
    'Efini MS-6',
    'Efini MS-8',
    'Efini MS-9',
    'Etude',
    'Eunos 100',
    'Eunos 300',
    'Eunos 500',
    'Eunos 800',
    'Eunos Cosmo',
    'Familia',
    'Flair',
    'Flair Crossover',
    'Flair Wagon',
    'Lantis',
    'Laputa',
    'Luce',
    'Millenia',
    'MPV',
    'MX-3',
    'MX-30',
    'MX-5',
    'MX-6',
    'Navajo',
    'Persona',
    'Premacy',
    'Proceed',
    'Proceed Levante',
    'Proceed Marvie',
    'Protege',
    'R360',
    'Revue',
    'Roadster',
    'RX-7',
    'RX-8',
    'Scrum',
    'Sentia',
    'Spiano',
    'Tribute',
    'Verisa',
    'Xedos 6',
    'Xedos 9'
  ],
  'Mercedes-Benz': [
    '190 (W201)',
    '190 SL',
    '220 (W187)',
    'A',
    'A AMG',
    'AMG GT',
    'B',
    'C',
    'C AMG',
    'Citan',
    'CL',
    'CL AMG',
    'CLA',
    'CLA AMG',
    'CLC',
    'CLK AMG GTR',
    'CLK',
    'CLK AMG',
    'CLS',
    'CLS AMG',
    'E',
    'E AMG',
    'EQA',
    'EQB',
    'EQC',
    'EQE',
    'EQS',
    'EQS AMG',
    'EQV',
    'G',
    'G AMG',
    'G AMG 6x6',
    'G',
    'G AMG',
    'GLA',
    'GLA AMG',
    'GLB',
    'GLB AMG',
    'GLC',
    'GLC AMG',
    'GLC Coupe',
    'GLC Coupe AMG',
    'GLE',
    'GLE AMG',
    'GLE Coupe',
    'GLE Coupe AMG',
    'GLK',
    'GLS',
    'GLS AMG',
    'M',
    'M AMG',
    'Maybach G 650 Landaulet',
    'Maybach GLS',
    'Maybach S',
    'Metris',
    'R',
    'R AMG',
    'S',
    'S AMG',
    'Simplex',
    'SL',
    'SL AMG',
    'SLC',
    'SLC AMG',
    'SLK',
    'SLK AMG',
    'SLR McLaren',
    'SLS AMG',
    'V',
    'Vaneo',
    'Viano',
    'Vito',
    'W100',
    'W105',
    'W108',
    'W110',
    'W111',
    'W114',
    'W115',
    'W120',
    'W121',
    'W123',
    'W124',
    'W128',
    'W136',
    'W138',
    'W142',
    'W186',
    'W188',
    'W189',
    'W191',
    'W29',
    'X'
  ],
  'Mercury': [
    'Capri',
    'Colony Park',
    'Cougar',
    'Eight',
    'Grand Marquis',
    'Marauder',
    'Mariner',
    'Marquis',
    'Milan',
    'Montego',
    'Monterey',
    'Mountaineer',
    'Mystique',
    'Sable',
    'Topaz',
    'Tracer',
    'Villager'
  ],
  'MINI': [
    'Cabrio',
    'Clubman',
    'Countryman',
    'Coupe',
    'Hatch',
    'Paceman',
    'Roadster'
  ],
  'Mitsubishi': [
    '3000 GT',
    '500',
    'Airtrek',
    'Aspire',
    'ASX',
    'Attrage',
    'Bravo',
    'Carisma',
    'Celeste',
    'Challenger',
    'Chariot',
    'Colt',
    'Cordia',
    'Debonair',
    'Delica',
    'Delica D:2',
    'Delica D:3',
    'Delica D:5',
    'Diamante',
    'Dignity',
    'Dingo',
    'Dion',
    'Eclipse',
    'Eclipse Cross',
    'eK Active',
    'eK Classic',
    'eK Custom',
    'eK Space',
    'eK Sport',
    'eK Wagon',
    'Emeraude',
    'Endeavor',
    'Eterna',
    'Freeca',
    'FTO',
    'Galant',
    'Galant Fortis',
    'Grandis',
    'GTO',
    'i',
    'i-MiEV',
    'Jeep J',
    'L200',
    'L300',
    'L400',
    'Lancer',
    'Lancer Cargo',
    'Lancer Evolution',
    'Lancer Ralliart',
    'Legnum',
    'Libero',
    'Minica',
    'Minicab',
    'Mirage',
    'Montero',
    'Montero Sport',
    'Outlander',
    'Pajero',
    'Pajero iO',
    'Pajero Junior',
    'Pajero Mini',
    'Pajero Pinin',
    'Pajero Sport',
    'Pistachio',
    'Proudia',
    'Raider',
    'RVR',
    'Sapporo',
    'Savrin',
    'Sigma',
    'Space Gear',
    'Space Runner',
    'Space Star',
    'Space Wagon',
    'Starion',
    'Strada',
    'Toppo',
    'Town Box',
    'Tredia',
    'Triton',
    'Xpander'
  ],
  'Nissan': [
    '100NX',
    '180SX',
    '200SX',
    '240SX',
    '280ZX',
    '300ZX',
    '350Z',
    '370Z',
    'AD',
    'Almera',
    'Almera Classic',
    'Almera Tino',
    'Altima',
    'Ariya',
    'Armada',
    'Auster',
    'Avenir',
    'Bassara',
    'BE-1',
    'Bluebird',
    'Bluebird Maxima',
    'Bluebird Sylphy',
    'Caravan',
    'Cedric',
    'Cefiro',
    'Cherry',
    'Cima',
    'Clipper Rio',
    'Crew',
    'Cube',
    'Datsun',
    'Dayz',
    'Dayz Roox',
    'Dualis',
    'Elgrand',
    'Exa',
    'Expert',
    'Fairlady Z',
    'Figaro',
    'Fuga',
    'Gloria',
    'GT-R',
    'Homy',
    'Hypermini',
    'Juke',
    'Juke Nismo',
    'Kicks',
    'Kix',
    'Lafesta',
    'Langley',
    'Largo',
    'Latio',
    'Laurel',
    'Leaf',
    'Leopard',
    'Liberta Villa',
    'Liberty',
    'Livina',
    'Lucino',
    'March',
    'Maxima',
    'Micra',
    'Mistral',
    'Moco',
    'Murano',
    'Navara (Frontier)',
    'Note',
    'NP300',
    'NV100 Clipper',
    'NV200',
    'NV300',
    'NV350 Caravan',
    'NX Coupe',
    'Otti',
    'Pao',
    'Pathfinder',
    'Patrol',
    'Pino',
    'Pixo',
    'Prairie',
    'Presage',
    'Presea',
    'President',
    'Primastar',
    'Primera',
    'Pulsar',
    'Qashqai',
    'Qashqai+2',
    'Quest',
    "R'nessa",
    'Rasheen',
    'Rogue',
    'Roox',
    'Safari',
    'Sentra',
    'Serena',
    'Silvia',
    'Skyline',
    'Skyline Crossover',
    'Stagea',
    'Stanza',
    'Sunny',
    'Sylphy Zero Emission',
    'Teana',
    'Terra',
    'Terrano',
    'Terrano Regulus',
    'Tiida',
    'Tino',
    'Titan',
    'Urvan',
    'Vanette',
    'Versa',
    'Wingroad',
    'X-Trail',
    'Xterra',
    'Z'
  ],
  'Oldsmobile': [
    'Achieva',
    'Alero',
    'Aurora',
    'Bravada',
    'Cutlass',
    'Cutlass Calais',
    'Cutlass Ciera',
    'Cutlass Supreme',
    'Eighty-Eight',
    'Firenza',
    'Intrigue',
    'Ninety-Eight',
    'Omega',
    'Series 60',
    'Series 70',
    'Silhouette',
    'Starfire',
    'Toronado',
    'Vista Cruiser'
  ],
  'Plymouth': [
    'Acclaim',
    'Barracuda',
    'Breeze',
    'Caravelle',
    'Fury',
    'Horizon',
    'Laser',
    'Neon',
    'Prowler',
    'Reliant',
    'Road Runner',
    'Satellite',
    'Sundance',
    'Turismo',
    'Valiant',
    'Volare',
    'Voyager'
  ],
  'Pontiac': [
    '6000',
    'Aztek',
    'Bonneville',
    'Catalina',
    'Fiero',
    'Firebird',
    'G4',
    'G5',
    'G6',
    'G8',
    'Grand AM',
    'Grand Prix',
    'GTO',
    'Laurentian',
    'LeMans',
    'Montana',
    'Parisienne',
    'Phoenix',
    'Solstice',
    'Sunbird',
    'Sunfire',
    'Tempest',
    'Torpedo',
    'Torrent',
    'Trans Sport',
    'Vibe',
    'Wave'
  ],
  'Porsche': [
    '356',
    '718 Spyder',
    '911',
    '911 GT2',
    '911 GT3',
    '911 R',
    '912',
    '914',
    '918 Spyder',
    '924',
    '928',
    '944',
    '959',
    '968',
    'Boxster',
    'Carrera GT',
    'Cayenne',
    'Cayenne Coupe',
    'Cayman',
    'Cayman GT4',
    'Macan',
    'Panamera',
    'Taycan'
  ],
  'Saab': [
    '600',
    '9-2X',
    '9-3',
    '9-4X',
    '9-5',
    '9-7X',
    '90',
    '900',
    '9000',
    '93',
    '95',
    '96',
    '99',
    'Sonett'
  ],
  'Saturn': [
    'Astra',
    'Aura',
    'ION',
    'LS',
    'LW',
    'Outlook',
    'Relay',
    'SC',
    'Sky',
    'SL',
    'SW',
    'VUE'
  ],
  'Scion': [
    'FR-S',
    'iA',
    'iM',
    'iQ',
    'tC',
    'xA',
    'xB',
    'xD'
  ],

  'Subaru': [
    '1000',
    '360',
    'Alcyone',
    'Ascent',
    'Baja',
    'Bighorn',
    'Bistro',
    'Brat',
    'BRZ',
    'Dex',
    'Dias Wagon',
    'Domingo',
    'Exiga',
    'Forester',
    'Impreza',
    'Impreza WRX',
    'Impreza WRX STi',
    'Justy',
    'Legacy',
    'Legacy Lancaster',
    'Leone',
    'Levorg',
    'Libero',
    'Lucra',
    'Outback',
    'Pleo',
    'Pleo Plus',
    'R1',
    'R2',
    'Rex',
    'Sambar',
    'Stella',
    'SVX',
    'Traviq',
    'Trezia',
    'Tribeca',
    'Vivio',
    'WRX',
    'WRX STi',
    'XT',
    'XV'
  ],
  'Suzuki': [
    'Across',
    'Aerio',
    'Alto',
    'Alto Lapin',
    'APV',
    'Baleno',
    'Cappuccino',
    'Cara',
    'Carry',
    'Celerio',
    'Cervo',
    'Cultus',
    'Equator',
    'Ertiga',
    'Escudo',
    'Esteem',
    'Every',
    'Forenza',
    'Fronte',
    'Grand Vitara',
    'Hustler',
    'Ignis',
    'Jimny',
    'Kei',
    'Kizashi',
    'Landy',
    'Liana',
    'MR Wagon',
    'Palette',
    'Reno',
    'Samurai',
    'Sidekick',
    'Solio',
    'Spacia',
    'Splash',
    'Swace',
    'Swift',
    'SX4',
    'Twin',
    'Verona',
    'Vitara',
    'Wagon R',
    'Wagon R+',
    'X-90',
    'Xbee',
    'XL7'
  ],
  'Tesla': [
    'Cybertruck',
    'Model 3',
    'Model S',
    'Model X',
    'Model Y',
    'Roadster'
  ],
  'Toyota': [
    '2000GT',
    '4Runner',
    'Allex',
    'Allion',
    'Alphard',
    'Altezza',
    'Aqua',
    'Aristo',
    'Aurion',
    'Auris',
    'Avalon',
    'Avanza',
    'Avensis',
    'Avensis Verso',
    'Aygo',
    'bB',
    'Belta',
    'Blade',
    'Blizzard',
    'Brevis',
    'C-HR',
    'Caldina',
    'Cami',
    'Camry',
    'Camry Solara',
    'Carina',
    'Carina E',
    'Carina ED',
    'Cavalier',
    'Celica',
    'Celsior',
    'Century',
    'Chaser',
    'Classic',
    'Comfort',
    'COMS',
    'Corolla',
    'Corolla Cross',
    'Corolla II',
    'Corolla Levin',
    'Corolla Rumion',
    'Corolla Spacio',
    'Corolla Verso',
    'Corona',
    'Corona EXiV',
    'Corsa',
    'Cressida',
    'Cresta',
    'Crown',
    'Crown Majesta',
    'Curren',
    'Cynos',
    'Duet',
    'Echo',
    'Esquire',
    'Estima',
    'Etios',
    'FJ Cruiser',
    'Fortuner',
    'FunCargo',
    'Gaia',
    'Grand HiAce',
    'Granvia',
    'GT86',
    'Harrier',
    'HiAce',
    'Highlander',
    'Hilux',
    'Hilux Surf',
    'Innova',
    'Ipsum',
    'iQ',
    'ISis',
    'Ist',
    'Kluger',
    'Land Cruiser',
    'Land Cruiser Prado',
    'Lite Ace',
    'Mark II',
    'Mark X',
    'Mark X ZiO',
    'MasterAce Surf',
    'Matrix',
    'Mega Cruiser',
    'Mirai',
    'Model F',
    'MR-S',
    'MR2',
    'Nadia',
    'Noah',
    'Opa',
    'Origin',
    'Paseo',
    'Passo',
    'Passo Sette',
    'Picnic',
    'Pixis Epoch',
    'Pixis Joy',
    'Pixis Mega',
    'Pixis Space',
    'Pixis Van',
    'Platz',
    'Porte',
    'Premio',
    'Previa',
    'Prius',
    'Prius Alpha',
    'Prius c',
    'Prius v (+)',
    'ProAce',
    'ProAce City',
    'Probox',
    'Progres',
    'Pronard',
    'Publica',
    'Ractis',
    'Raize',
    'Raum',
    'RAV4',
    'Regius',
    'RegiusAce',
    'Roomy',
    'Rush',
    'Sai',
    'Scepter',
    'Sequoia',
    'Sera',
    'Sienna',
    'Sienta',
    'Soarer',
    'Soluna',
    'Spade',
    'Sparky',
    'Sports 800',
    'Sprinter',
    'Sprinter Carib',
    'Sprinter Marino',
    'Sprinter Trueno',
    'Starlet',
    'Succeed',
    'Supra',
    'Tacoma',
    'Tank',
    'Tercel',
    'Touring HiAce',
    'Town Ace',
    'Tundra',
    'Urban Cruiser',
    'Vanguard',
    'Vellfire',
    'Venza',
    'Verossa',
    'Verso',
    'Verso-S',
    'Vios',
    'Vista',
    'Vitz',
    'Voltz',
    'Voxy',
    'WiLL',
    'WiLL Cypha',
    'Windom',
    'Wish',
    'Yaris',
    'Yaris Verso'
  ],
  'Volkswagen': [
    '181',
    'Amarok',
    'Arteon',
    'Arteon R',
    'Atlas',
    'Atlas Cross Sport',
    'Beetle',
    'Bora',
    'Caddy',
    'California',
    'Caravelle',
    'Corrado',
    'Derby',
    'Eos',
    'EuroVan',
    'Fox',
    'Gol',
    'Golf',
    'Golf Country',
    'Golf GTI',
    'Golf Plus',
    'Golf R',
    'Golf R32',
    'Golf Sportsvan',
    'ID.3',
    'ID.4',
    'Iltis',
    'Jetta',
    'K70',
    'Karmann-Ghia',
    'Lavida',
    'Lupo',
    'Lupo GTI',
    'Multivan',
    'Parati',
    'Passat',
    'Passat (North America)',
    'Passat CC',
    'Phaeton',
    'Pointer',
    'Polo',
    'Polo GTI',
    'Polo R WRC',
    'Quantum',
    'Routan',
    'Santana',
    'Scirocco',
    'Scirocco R',
    'Sharan',
    'SpaceFox',
    'T-Cross',
    'T-Roc',
    'T-Roc R',
    'Taos',
    'Taro',
    'Teramont',
    'Tharu',
    'Tiguan',
    'Tiguan R',
    'Touareg',
    'Touran',
    'Transporter',
    'Type 1',
    'Type 166',
    'Type 2',
    'Type 3',
    'Type 4',
    'Type 82',
    'up!',
    'Vento',
    'XL1'
  ],
  'Volvo': [
    '120 Series',
    '140 Series',
    '164',
    '240 Series',
    '260 Series',
    '300 Series',
    '440',
    '460',
    '480',
    '66',
    '740',
    '760',
    '780',
    '850',
    '940',
    '960',
    'C30',
    'C40',
    'C70',
    'Laplander',
    'P1800',
    'P1900',
    'PV444',
    'PV544',
    'S40',
    'S60',
    'S60 Cross Country',
    'S70',
    'S80',
    'S90',
    'V40',
    'V40 Cross Country',
    'V50',
    'V60',
    'V60 Cross Country',
    'V70',
    'V90',
    'V90 Cross Country',
    'XC40',
    'XC60',
    'XC70',
    'XC90'
  ],

};
