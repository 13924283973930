// eslint-disable-next-line import/no-anonymous-default-export
export default {
  primary: [
    "username",
    "phone",
    { title: "Metro", field: "metro" },
    "otherMetro",
    "notes",
    "company"
  ],
  personal: ["firstName", "lastName", "address", "city" , "state" , "zipCode"],
  timeSchedule: [
    "days",
    "time",
    { title: "Time Amount", field: "timeAmount.title" },
  ],
  carVehicle: [
    { title: "Car Type", field: "carType.title" },
    { title: "Vehicle Year", field: "vehicle.year" },
    { title: "Vehicle Brand", field: "vehicle.brand" },
    { title: "Vehicle Model", field: "vehicle.model" },
  ],
  onboarding: [
    { title: "Tax Fill Status", field: "onboardingTaxFillStatus" },
    { title: "Insurance Fill Status", field: "onboardingInsuranceFillStatus" },
    // { title: "Videos Fill Status", field: "onboardingVideosFillStatus" },
    {
      title: "Background Fill Status",
      field: "onboardingBackgroundFillStatus",
    },
    { title: "Deposit Fill Status", field: "onboardingDepositFillStatus" },
    {
      title: "Driver Licence Fill Status",
      field: "onboardingLicenceFillStatus",
    },
    {
      title: "Vehicle Fill Status",
      field: "onboardingVehicleFillStatus",
    },
    {
      title: "Driver Badge Fill Status",
      field: "onboardingDriverBadgeFillStatus",
    },
    {
      title: "OAI Insurance Fill Status",
      field: "onboardingDriverOAIFillStatus",
    },
  ],
  basic: ["language", "taxClass", "isUSPerson", "isAgent", "1099 Consent", "consentTime"],
  signature: [
    { title: "Signature W9 Agreement", field: "isSignatureW9agreement" },
    { title: "Sign", field: "signatureW9.sign" },
    { title: "Date Day", field: "signatureW9.DateDay" },
    { title: "Date Month", field: "signatureW9.DateMonth" },
    { title: "Date Year", field: "signatureW9.DateYear" },
  ],
  tax: ["tradeName", "typeTin", "EIN", "SSN", "country"],
  taxpayerRequest: [
    { title: "Organizational Legal Form", field: "organizationalLegalForm" },
    { title: "Limited liability Company", field: "limitedComp" },
    { title: "Other Company", field: "otherComp" },
    "requesterName",
    "requesterAddress",
    "requesterCityState",
    "accountNumber",
    { title: "Insurance Agree", field: "isInsuranceAgree" },
  ],
  driverLicense1: [
    { title: "Birthday", field: "driverLicenseBirthday" },
    { title: "Date Expiration", field: "driverLicenseDateExp" },
  ],
  driverLicense2: [
    { title: "Number", field: "driverLicenseNumber" },
    { title: "State", field: "driverLicenseState" },
  ],
  insurancePolicy: [
    { title: "Date Expiration", field: "insuranceDateExp" },
    { title: "Policy Number", field: "insurancePolicyNumber" },
    { title: "State", field: "insuranceState" },
  ],
  OIA_insurance: [
    { title: "Date Expiration", field: "OAI.exp_date" },
    { title: "Policy Number", field: "OAI.policy_no" },
  ],
  background: [
    { title: "Has Been Convicted", field: "isConvicted" },
    { title: "Has Previous Names", field: "isPrevNames" },
    { title: "Send Background Copy", field: "isSendBackgroundCopy" },
  ],
  deposit: [
    { title: "Holder Name", field: "depositHolderName" },
    { title: "Type", field: "depositType" },
    { title: "Routing Number", field: "depositRoutingNumber" },
    { title: "Account Number", field: "depositAccountNumber" },
  ],
  deviceInfo: [
    { title: "Last Login at", field: "lastLoginTime" },
    { title: "Device", field: "deviceInfo.type" },
    { title: "Model", field: "deviceInfo.model" },
    {
      title: "App Version",
      field: "deviceInfo.appVersion",
    },
  ],
};
