import React, { useCallback } from 'react'
import { CircularProgress, Modal } from '@mui/material';
import { actions } from '../../../../redux/store/store';
import Table from '../../Table';
import { useInterestedDriverQuery, useUpdateBulkDriverAssignMutation } from '../../../../api/orders';
import { useEffect } from 'react';
import { getRoute } from '../../../../util/time';
import { capitalizeFirstLetter } from '../../helper';
import { checkForError } from '../../../../constants/extras/errorHandlers';
import { API_SUCCESS } from '../../../../constants/variables/successMessage';
import { sendSuccessMessage } from '../../../../constants/extras/sendSuccessMsg';
import { useSelector } from "react-redux";
import ConfirmDialogModal from "../../../shared/customModal/confirmDialog";
import { useState } from 'react';
import moment from "moment";

function InterestedDriverModal(props) {
  const { id } = props;
  const { UPDATED } = API_SUCCESS.SHIFT;

  const currentUser = useSelector((state) => state.auth.currentUser);
  const currentMetro = useSelector((state) => state?.metro?.value);
  const currentMetros = useSelector((state) => state?.metro?.metros);

  const [showDialog, setShowDialog] = useState(false);
  const [payload, setPayload] = useState([]);

  const { data, isLoading, refetch } = useInterestedDriverQuery({ id })
  const [update, { isLoading: isUpdating }] = useUpdateBulkDriverAssignMutation();

  const onCancel = useCallback(() => {
    actions.modal.closeInterestedDriver();
  }, []);

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  const handleSelectAll = (isChecked) => {
    const newPayload = isChecked
      ? data.result.map(
        driver => ({
          id: driver._id,
          shiftId: driver.uniqueTimeDatePairs[0].shiftId
        })
      ) : []
    setPayload(newPayload);
  }

  const handleSelect = (driverId, shiftId, isItemSelected) => {
    const newPayload = [...payload].filter(({ id }) => id !== driverId)
    const newSelectedItems = isItemSelected
      ? newPayload
      : [...newPayload, { id: driverId, shiftId: shiftId }];
    setPayload(newSelectedItems);
  }

  const handleAssignAll = async () => {
    if (!data?.result?.length) return;

    const result = await update(payload);

    if (!result?.error && result?.data?.status === 200) {
      sendSuccessMessage(UPDATED);
      actions.refetch.setIsDataRefetch(true);
      onCancel();
    } else checkForError(result);
  };

  const columns = [
    ...((
      (currentMetro || !!currentMetros?.length)
      && ['root', 'admin'].includes(currentUser.role)
    )
      ? [{
        name: "checkbox",
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              onClick={(e) => handleSelectAll(e.target.checked)}
              onChange={() => { }}
              checked={payload.length === data?.result?.length}
              className="mainCheckbox"
            />
          </div>
        ),
        className: "table__td_has-checkbox",
        minWidth: "0",
        maxWidth: "15px",
        renderer: (item) =>
          item.uniqueTimeDatePairs.map(({ shiftId }) => {
            const isSelected = (driverId) => payload.find(e => e.id === driverId && e.shiftId === shiftId);
            const isItemSelected = isSelected(item._id);
            return (
              <div className="orders-input-checkbox">
                <input
                  type="checkbox"
                  onClick={() => handleSelect(item._id, shiftId, isItemSelected)}
                  onChange={() => { }}
                  checked={isItemSelected}
                />
              </div>
            )
          })
      }] : []
  ),
    {
      name: "firstName",
      label: "Driver Name",
      maxWidth: "50px",
      sortable: true,
      renderer: (item) => (
        <span>{`${item?.firstName || ''} ${item?.lastName || ''}`}</span>
      ),
    },
    {
      name: "metro",
      label: "Metro",
      maxWidth: "60px",
      sortable: true,
      renderer: ({ metro }) => <span>{metro || '-'}</span>
    },
    {
      name: "orderCount",
      label: "Shift Count",
      maxWidth: "15px",
      minWidth: "0",
      sortable: true,
    },
    {
      name: "carType",
      label: "Vehicle Type",
      maxWidth: "60px",
      sortable: true,
    },
    {
      name: "interestedDate",
      label: "Interested At",
      maxWidth: "40px",
      sortable: true,
      renderer: ({ uniqueTimeDatePairs }) => (
        <div style={{ margin: 0, display: 'flex', flexDirection: 'column' }}>
          {
            uniqueTimeDatePairs?.map((e) => (
              <span>{moment(e.interestedDate).format("MM-DD-YYYY hh:mm A")}</span>
            ))
          }
        </div>
      )
    },
    {
      name: "startTime",
      label: "Start Time",
      maxWidth: "30px",
      minWidth: "0",
      sortable: true,
      renderer: ({ uniqueTimeDatePairs }) => (
        <div style={{ margin: 0, display: 'flex', flexDirection: 'column' }}>
          {
            uniqueTimeDatePairs?.map((e) => (
              <span>{getRoute(e.startTime)}</span>
            ))
          }
        </div>
      )
    }
  ];

  return (
    <>
      <Modal
        open
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={`modal after-loading`} style={{ maxWidth: "70rem" }}>
          <div
            className="modal__header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2 className="modal__title">
              {capitalizeFirstLetter(id?.shiftType)} Interested Driver Details
            </h2>
            {((currentMetro || !!currentMetros?.length) && ['root', 'admin'].includes(currentUser.role))
              && <button
                className="btn btn_accent"
                onClick={() => setShowDialog(true)}
                disabled={!payload.length || isUpdating}
              >
                Assign Driver {!!payload.length && `(${payload.length})`}
              </button>
            }
          </div>
          <div className="modal__body" style={{ position: "relative" }}>
            <div className="card" style={{ border: "1px solid #DADADA", opacity: isLoading || isUpdating ? 0.5 : 1 }}>
              <div
                style={{
                  height: "60vh",
                  overflow: "auto",
                }}
              >
                <Table columns={columns} items={data?.result} />
              </div>
            </div>
            {(isLoading || isUpdating) && (
              <div
                className="inner__scroll__view__container"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,  // Ensures the loader is above the table
                }}
              >
                <CircularProgress className="loading-progress" />
              </div>
            )}
          </div>
          <div className="modal__footer">
            <button onClick={onCancel} disabled={isUpdating} className="btn">
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {showDialog &&
        <ConfirmDialogModal
          title="Assign All Driver"
          bodyText="Are you sure you want to assign all drivers?"
          isOpen
          isLoading={isUpdating}
          confirmAction={handleAssignAll}
          onClose={() => setShowDialog(false)}
        />
      }
    </>
  );
}

export default InterestedDriverModal
