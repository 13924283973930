import EditIcon from "@mui/icons-material/Edit";
import { actions } from "../../../../redux/store/store";

const EditVehicle = (props) => {
  const { id, currentUser, driver } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>{driver?.carType?.title}</span>
      {currentUser.role === "root" &&
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            actions.modal.openVehicle({
              driver,
              id,
            })
          }
        >
          <EditIcon style={{ width: "20px", height: "20px" }} />
        </span>
      }
    </div>
  )
}

export default EditVehicle;