import { createSlice } from "@reduxjs/toolkit";

const emptyObj = { open: false, id: null };

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    order: emptyObj,
    driver: emptyObj,
    store: emptyObj,
    transaction: emptyObj,
    reason: emptyObj,
    adhocpayment: emptyObj,
    directpayment: emptyObj,
    calendarPayment: emptyObj,
    shifttemplate: emptyObj,
    metro: emptyObj,
    orderLogs: emptyObj,
    oaiLogs: emptyObj,
    email: emptyObj,
    notification: emptyObj,
    notes: emptyObj,
    phone: emptyObj,
    driverLicense: emptyObj,
    insurancePolicy: emptyObj,
    oai_insurance: emptyObj,
    pipeline: emptyObj,
    company: emptyObj,
    companymain: emptyObj,
    feedback: emptyObj,
    schedule: emptyObj,
    vehicle: emptyObj,
    driverCoaching: emptyObj,
    coachingHistory: emptyObj,
    coaching: emptyObj,
    addException: emptyObj,
    reating: emptyObj,
    failedPayment: emptyObj,
    interestedDriver: emptyObj,
    payoutWarning: emptyObj,
    pods: emptyObj,
    bulkOrder: emptyObj
  },
  reducers: {
    openShiftTemplate: (state, { payload }) => {
      state.shifttemplate = { open: true, id: payload };
    },
    openOrder: (state, { payload }) => {
      state.order = { open: true, id: payload };
    },
    openDriver: (state, { payload }) => {
      state.driver = { open: true, id: payload };
    },
    openStore: (state, { payload }) => {
      state.store = { open: true, id: payload };
    },
    openTransaction: (state, { payload }) => {
      state.transaction = { open: true, id: payload };
    },
    openReason: (state, { payload }) => {
      state.reason = { open: true, id: payload };
    },
    openAdhocpayment: (state, { payload }) => {
      state.adhocpayment = { open: true, id: payload };
    },
    openDirectpayment: (state, { payload }) => {
      state.directpayment = { open: true, id: payload };
    },
    openCalendarPayment: (state, { payload }) => {
      state.calendarPayment = { open: true, id: payload };
    },
    openMetro: (state, { payload }) => {
      state.metro = { open: true, id: payload };
    },
    openOrderLogs: (state, { payload }) => {
      state.orderLogs = { open: true, id: payload };
    },
    openOAILogs: (state, { payload }) => {
      state.oaiLogs = { open: true, id: payload };
    },
    openEmail: (state, { payload }) => {
      state.email = { open: true, id: payload };
    },
    openNotification: (state, { payload }) => {
      state.notification = { open: true, id: payload };
    },
    openNotes: (state, { payload }) => {
      state.notes = { open: true, id: payload };
    },
    openPhone: (state, { payload }) => {
      state.phone = { open: true, id: payload };
    },
    openDriverLicense: (state, { payload }) => {
      state.driverLicense = { open: true, id: payload };
    },
    openInsurancePolicy: (state, { payload }) => {
      state.insurancePolicy = { open: true, id: payload };
    },
    openOAIInsurance: (state, { payload }) => {
      state.oai_insurance = { open: true, id: payload };
    },
    openPipeline: (state, { payload }) => {
      state.pipeline = { open: true, id: payload };
    },
    openCompany: (state, { payload }) => {
      state.company = { open: true, id: payload };
    },
    openCompanyMain: (state, { payload }) => {
      state.companymain = { open: true, id: payload };
    },
    openFeedback: (state, { payload }) => {
      state.feedback = { open: true, id: payload };
    },
    openSchedule: (state, { payload }) => {
      state.schedule = { open: true, id: payload };
    },
    openVehicle: (state, { payload }) => {
      state.vehicle = { open: true, id: payload };
    },
    openDriverChoching: (state, { payload }) => {
      state.driverCoaching = { open: true, id: payload };
    },
    openCoaching: (state, { payload }) => {
      state.coaching = { open: true, id: payload };
    },
    openCoachingHistory: (state, { payload }) => {
      state.coachingHistory = { open: true, id: payload };
    },
    openException: (state, { payload }) => {
      state.addException = { open: true, id: payload };
    },
    openRating: (state, { payload }) => {
      state.reating = { open: true, id: payload };
    },
    openFailedPayment: (state, { payload }) => {
      state.failedPayment = { open: true, id: payload };
    },
    openInterestedDriver: (state, { payload }) => {
      state.interestedDriver = { open: true, id: payload };
    },
    openPayoutWarning: (state, { payload }) => {
      state.payoutWarning = { open: true, id: payload };
    },
    openPodsModal: (state, { payload }) => {
      state.pods = { open: true, id: payload };
    },
    openBulkOrderModal: (state, { payload }) => {
      state.bulkOrder = { open: true, id: payload };
    },

    closeShiftTemplate: (state) => {
      state.shifttemplate = emptyObj;
    },
    closeOrder: (state) => {
      state.order = emptyObj;
    },
    closeDriver: (state) => {
      state.driver = emptyObj;
    },
    closeStore: (state) => {
      state.store = emptyObj;
    },
    closeTransaction: (state) => {
      state.transaction = emptyObj;
    },
    closeReason: (state) => {
      state.reason = emptyObj;
    },
    closeAdhocpayment: (state) => {
      state.adhocpayment = emptyObj;
    },
    closeDirectpayment: (state) => {
      state.directpayment = emptyObj;
    },
    closeCalendarPayment: (state) => {
      state.calendarPayment = emptyObj;
    },
    closeMetro: (state) => {
      state.metro = emptyObj;
    },
    closeOrderLogs: (state) => {
      state.orderLogs = emptyObj;
    },
    closeOAILogs: (state) => {
      state.oaiLogs = emptyObj;
    },
    closeEmail: (state) => {
      state.email = emptyObj;
    },
    closeNotification: (state) => {
      state.notification = emptyObj;
    },
    closeNotes: (state) => {
      state.notes = emptyObj;
    },
    closePhone: (state) => {
      state.phone = emptyObj;
    },
    closeDriverLicense: (state) => {
      state.driverLicense = emptyObj;
    },
    closeInsurancePolicy: (state) => {
      state.insurancePolicy = emptyObj;
    },
    closeOAIInsurance: (state) => {
      state.oai_insurance = emptyObj;
    },
    closePipeline: (state) => {
      state.pipeline = emptyObj;
    },
    closeCompany: (state, { payload }) => {
      state.company = emptyObj;
    },
    closeCompanyMain: (state) => {
      state.companymain = emptyObj;
    },
    closeFeedback: (state, { payload }) => {
      state.feedback = { open: false, id: payload };
    },
    closeSchedule: (state) => {
      state.schedule = emptyObj;
    },
    closeVehicle: (state) => {
      state.vehicle = emptyObj;
    },
    closeDriverChoching: (state, { payload }) => {
      state.driverCoaching = emptyObj;
    },
    closeCoaching: (state) => {
      state.coaching = emptyObj;
    },
    closeCoachingHistory: (state) => {
      state.coachingHistory = emptyObj;
    },
    closeException: (state) => {
      state.addException = emptyObj;
    },
    closeRating: (state) => {
      state.reating = emptyObj;
    },
    closeFailedPayment: (state) => {
      state.failedPayment = emptyObj;
    },
    closeInterestedDriver: (state) => {
      state.interestedDriver = emptyObj;
    },
    closePayoutWarning: (state, { payload }) => {
      state.payoutWarning = emptyObj;
    },
    closePodsModal: (state, { payload }) => {
      state.pods = emptyObj;
    },
    closeBulkOrderModal: (state, { payload }) => {
      state.bulkOrder = emptyObj;
    },
  },
});

export const {
  openShiftTemplate,
  openOrder,
  openDriver,
  openStore,
  openTransaction,
  openReason,
  openAdhocpayment,
  openDirectpayment,
  openCalendarPayment,
  openMetro,
  openOrderLogs,
  openEmail,
  openNotification,
  openNotes,
  openPhone,
  openDriverLicense,
  openInsurancePolicy,
  openPipeline,
  openCompany,
  openCompanyMain,
  openFeedback,
  openSchedule,
  openDriverChoching,
  openCoaching,
  openCoachingHistory,
  openRating,
  openFailedPayment,
  openInterestedDriver,
  openPayoutWarning,
  openPodsModal,
  openBulkOrderModal,
  closeOrder,
  closeDriver,
  closeStore,
  closeTransaction,
  closeReason,
  closeAdhocpayment,
  closeDirectpayment,
  closeCalendarPayment,
  closeShiftTemplate,
  closeMetro,
  closeOrderLogs,
  closeEmail,
  closeNotification,
  closeNotes,
  closePhone,
  closeDriverLicense,
  closeInsurancePolicy,
  closePipeline,
  closeCompany,
  closeCompanyMain,
  closeFeedback,
  closeSchedule,
  closeDriverChoching,
  closeCoaching,
  closeCoachingHistory,
  closeRating,
  closeFailedPayment,
  closeInterestedDriver,
  closePayoutWarning,
  closePodsModal,
  closeBulkOrderModal,
  openVehicle,
  closeVehicle,
  openOAIInsurance,
  closeOAIInsurance
} = modalSlice.actions;
export default modalSlice.reducer;
