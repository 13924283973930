import { useCallback, useState } from "react";
import { CircularProgress, Modal } from "@mui/material";
import { useUpdateDriverMutation } from "../../../api/drivers";
import { actions } from "../../../redux/store/store";
import { DateCalendar } from "../DatePicker";
import moment from "moment";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import { checkForError } from "../../../constants/extras/errorHandlers";

const OAIModal = ({ id: data }) => {
  const title = data?.title
  const { OAI } = data?.driverData;
  const IDE = OAI?.exp_date ? new Date(OAI?.exp_date) : '';

  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState(OAI?.policy_no || "");
  const [insuranceDateExp, setInsuranceDateExp] = useState(IDE)
  const [previnsuranceDateExp, setprevInsuranceDateExp] = useState(insuranceDateExp || '')
  const [showInsuranceDateExp, setShowInsuranceDateExp] = useState(false);

  const [updateDriver, { isLoading: isUpdatingDriver }] = useUpdateDriverMutation();

  const onCancel = useCallback(() => {
    actions.modal.closeOAIInsurance();
  }, []);

  function changeDate(e) {
    let date = moment(previnsuranceDateExp).date();
    let new_date = moment(e).set('D', date)
    const today = moment()
    const diff = today.diff(new_date, 'days');
    if (diff >= 0) {
      new_date = moment()
    }
    setprevInsuranceDateExp(new_date.toDate())
  }

  const handleSubmit = async () => {
    const result = await updateDriver({
      _id: data?.id,
      OAI: {
        ...OAI,
        policy_no: insurancePolicyNumber,
        exp_date: moment(insuranceDateExp).format('MM/DD/YYYY')
      }
    });
    if (!result?.error) {
      sendSuccessMessage(`${title} updated successfully`);
      actions.refetch.setIsDataRefetch(true);
    } else {
      checkForError(result);
    }
    onCancel();
  }

  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`modal after-loading`} style={{ maxWidth: "35.875rem" }}>
        <div className="modal__header">
          <h2 className="modal__title">{title}</h2>
        </div>
        <div className="modal__body">
          <div className="driver_license" style={{ height: showInsuranceDateExp ? "40vh" : "auto" }}>
            <span className="fw-600">Date Expiration: </span>
            <DateCalendar
              handleCalendarDate={() => {
                if (showInsuranceDateExp) {
                  setprevInsuranceDateExp(insuranceDateExp)
                }
                setShowInsuranceDateExp(!showInsuranceDateExp)
              }}
              date={previnsuranceDateExp}
              showDate={previnsuranceDateExp}
              showDatePicker={showInsuranceDateExp}
              minDate={moment().toDate()}
              onChange={(e) => setprevInsuranceDateExp(e)}
              onShownDateChange={(e) => changeDate(e)}
              submitDate={() => {
                setInsuranceDateExp(previnsuranceDateExp)
                setShowInsuranceDateExp(false)
              }}
              cancelDate={() => {
                setprevInsuranceDateExp(insuranceDateExp)
                setShowInsuranceDateExp(false)
              }}
              placeholder="Insurance Date Expiration"
              sx={{ position: "relative" }}
              resetDate={() => {
                setInsuranceDateExp(IDE)
                setprevInsuranceDateExp(IDE)
                setShowInsuranceDateExp(false)
              }}
            />
          </div>
          <div className="driver_license">
            <span className="fw-600">Policy Number: </span>
            <input
              className={`form__input `}
              type={"text"}
              id={'insurancePolicyNumber'}
              value={insurancePolicyNumber}
              onChange={(e) => setInsurancePolicyNumber(e?.target?.value)}
              autoComplete="off"
              placeholder="Policy Number"
            />
          </div>
        </div>
        <div className="modal__footer">
          <button className="btn" onClick={onCancel}>
            Cancel
          </button>
          <div style={{ position: 'relative' }}>
            <button
              className="btn btn_accent"
              disabled={isUpdatingDriver}
              onClick={handleSubmit}
            >
              OK
            </button>
            {isUpdatingDriver && (
              <CircularProgress size={18}
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  top: '50%',
                  left: '83%',
                  marginTop: '-8px',
                  marginLeft: '-10px',
                }} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OAIModal;