import React, { Fragment, useCallback, useMemo } from 'react'
import { useGetOAILogsQuery } from '../../../../api/logs'
import { actions } from '../../../../redux/store/store';
import { CircularProgress, Modal } from '@mui/material';
import Table from '../../Table';
import moment from "moment";

export const DriverLogsModal = ({ id }) => {
  const { data: oaiLogs, isFetching } = useGetOAILogsQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const onCancel = useCallback(() => {
    actions.modal.closeOAILogs();
  }, []);

  const columns = useMemo(() => [
    {
      name: "timestamp",
      label: "Date",
      sortable: true,
      className: "w-20 py-2",
      renderer: ({ timestamp }) => moment(timestamp).format("MM-DD-YYYY hh:mm A"),
    },
    {
      name: "admin-username",
      label: "Action Performer",
      className: "w-25",
      sortable: true,
      renderer: ({ admin }) => admin?.username || "-",
    },
    {
      name: "result",
      label: "Description",
      className: "",
      sortable: true,
      renderer: ({ payload }) => {
        if (!payload) return "Policy image changed";
        const { after } = payload;

        if (after?.isOwnOAI) return `Policy no: ${after.policy_no}, Expiry Date: ${after.exp_date}`
        if (after?.isOwnOAI === false) return "Purchased via Better Trucks";
        return "Insurance data deleted"
      },
    },
  ], [oaiLogs]);
  return (
    <Modal
      open
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`modal ${!isFetching ? "after-loading" : "when-loading"}`}
        style={{ maxWidth: "55rem" }}
      >
        <div className="modal__header">
          <h2 className="modal__title">Occupational Accident Insurance logs</h2>
        </div>
        <div className="modal__body">
          {isFetching ? (
            <div
              className="inner__scroll__view__container"
              style={{ height: "8rem" }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              {oaiLogs &&
                oaiLogs?.status === 200 &&
                !oaiLogs?.error &&
                oaiLogs?.result?.length > 0 ? (
                <div className="card" style={{ border: "1px solid #DADADA" }}>
                  <div
                    style={{
                      height: "30vh",
                      overflow: "auto",
                    }}
                  >
                    <Table
                      columns={columns}
                      items={oaiLogs && oaiLogs?.result}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="inner__scroll__view__container"
                  style={{ height: "10vh" }}
                >
                  No result found
                </div>
              )}
            </Fragment>
          )}
        </div>
        <div className="modal__footer">
          <button
            className="btn btn_accent"
            onClick={onCancel}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  )
}
