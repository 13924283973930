import _ from "lodash";
import moment from "moment";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Modal,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  Box,
  Autocomplete,
  OutlinedInput,
  styled,
  Tooltip,
  Grid,
  Checkbox,
} from "@mui/material";
import { FaClipboard } from "react-icons/fa";
import { RiFileTransferFill } from "react-icons/ri";
import { GoLinkExternal } from "react-icons/go";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import MetroSelect from "../../../shared/metroSelect";
import { InputItem, ErrorMessage } from "../../../shared/Form";
import {
  useGetOrderQuery,
  useUpdateOrderMutation,
  useCreateOrderMutation,
  useChangeStatusMutation,
  useApproveOrderPaymentMutation,
  useRemoveOrderMutation,
  useGetPodDataQuery,
  useGetUnaccountedPackagesQuery,
  useLazyGetPodDataQuery,
} from "../../../../api/orders";
import { useGetStoresQuery } from "../../../../api/stores";
import { useGetShiftTemplatesQuery } from "../../../../api/shiftTemplate";
import { Times } from "../../../../util/time";
import { actions } from "../../../../redux/store/store";
import ConfirmDialogModal from "../../../shared/customModal/confirmDialog";
import Timepicker from "../../../shared/Timepicker";
import Hourpicker from "../../../shared/Hourpicker";

import "../style.scss";
import "./style.order.scss";
import { useSendSlackMessageMutation } from "../../../../api/slackMessage";
import { handlePaymentStatusForDetails } from "../../../../constants/extras/convertToCapitalize";
import { ordersValidationSchema as validationSchema } from "../../../../constants/validaitonSchema";
import { handledriverName } from "../../../../constants/extras/handleDriverName";
import { checkForError as errorHandlers, sendErrorMessage } from "../../../../constants/extras/errorHandlers";
import AssignDriver from "../../orderHelper/AssignDriver";
import { setInputFilter } from "../../orderHelper/setCurrency";
import DeviceAndLoginInfo from "../../orderHelper/DeviceInfo";
import { sendSuccessMessage } from "../../../../constants/extras/sendSuccessMsg";
import {
  autocomplete,
  hoverAndFocusedStyle,
  hubSelectStyle,
  select,
} from "../../orderHelper/styles";
import RouteDateRange from "../../orderHelper/RouteDateRange";
import RejectShift from "../../orderHelper/RejectShift";
import { API_SUCCESS } from "../../../../constants/variables/successMessage";
import { checkArrForMapping } from "../../../../constants/validaitonSchema/validation.ts";
import { handleCurrency as handleCurrencyPrice } from "../../../../constants/extras/handleNumbers";
import { toggleTime } from "../../../../constants/variables/dates";
import { MSG, MODAL_TITLE } from "../../../../constants/variables/infoMsg";
import { rewordingOrderStatus } from "../../../../constants/extras/rewording";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { usePaymentDriverMutation } from "../../../../api/drivers";
import { Button } from "react-bootstrap";
import PayoutWarning from "../payoutWarning";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactSelect from 'react-select'
import { shiftTypeOption } from "../../../../constants/menuLists.js";

const arrOfZeroToFour = [...Array(5).keys()];
const { DELIVERY_STARTED, SHIFT_COMPLETED, SHIFT_REJECTED, CREATED, UPDATED, CANCELLED_SINGLE } =
  API_SUCCESS.SHIFT;
const { REJECTING_SHIFT, FINAL__PAYMENT } = MODAL_TITLE.SHIFT;

const customizedCircularProgress = {
  height: "1.3rem",
  width: "1.3rem",
  color: "#FFFFFF",
};
const formLabelStyle = {
  display: "inline-block",
  margin: "auto",
  padding: "0 1rem",
};
const refetchObj = {
  refetchOnMountOrArgChange: true,
};
const arrForAssignDriver = ["created", "assigned"];

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#f61818"
    },
    "&:hover $notchedOutline": {
      borderColor: "#f61818"
    },
    "&$focused $notchedOutline": {
      borderColor: "#f61818"
    }
  },
  focused: {},
  notchedOutline: {}
}));


const MuiAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid hsl(0, 0%, 95%)`,
  borderRadius: "0.625rem",
  '&:before': {
    display: 'none',
  },
}));

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid hsl(0, 0%, 95%)',
  maxHeight: "500px",
  overflow: "auto",
  padding: "0 16px"
}));

const MuiAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(() => ({
  '& .MuiAccordionSummary-content': {
    fontSize: "13px"
  },
}));

const CustomModal = (props) => {
  const outlinedInputClasses = useOutlinedInputStyles();
  const obj = props && props.id && props.id.item && props.id.item;
  const [assignedDriver, setAssignedDriver] = useState(null);
  const firstName = assignedDriver?.firstName || obj?.driver?.firstName || {};
  const lastName = assignedDriver?.lastName || obj?.driver?.lastName || {};
  const email = assignedDriver?.username || obj?.driver?.username || {};
  const driverId = assignedDriver?._id || obj?.driver?._id;
  const id = obj?._id;
  const title = id ? `Shift #${id} details` : "New Shift";
  const path = props.id?.path;
  const [
    changeStatusForApproveOrderPayments,
    { isLoading: isApproveOrderLoading },
  ] = useApproveOrderPaymentMutation();

  const payoutWarning = useSelector((state) => state.modal.payoutWarning);

  const [sendSlackMessage] = useSendSlackMessageMutation();

  const { data: order, isFetching: isOrderLoading } = useGetOrderQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const [getPodDataApi, { isFetching: isPodLoading }] = useLazyGetPodDataQuery()

  // const { data: getPodData, isFetching: isPodLoading } = useGetPodDataQuery(id, {
  //   ...refetchObj,
  //   skip: !id || obj?.status === "created" || obj?.status === "cancelled" || obj?.status === "assigned" || obj?.status === "rejected",
  // })

  const { data: getUnaccounted, isFetching: UnaccountedLoading } = useGetUnaccountedPackagesQuery(id, {
    ...refetchObj,
    skip: !id || obj?.status === "created" || obj?.status === "cancelled" || obj?.status === "assigned" || obj?.status === "rejected" || obj?.status === "delivery_started" || obj?.shiftType !== "DELIVERY",
  })

  const { data: stores, isFetching: isStoreLoading } = useGetStoresQuery(
    null,
    refetchObj
  );

  const { data: shiftTemplates } = useGetShiftTemplatesQuery(null, refetchObj);

  const [remove, removeReq] = useRemoveOrderMutation();
  const [update, { error: updateError, isLoading: isUpdating }] =
    useUpdateOrderMutation();
  const [create, { error: createError, isLoading: isCreating }] =
    useCreateOrderMutation();
  const [changeStatus, { isLoading: isChangingStatus }] =
    useChangeStatusMutation();

  const [generalError, setGeneralError] = useState(null);
  const [priceError, setPriceError] = useState(null);

  const [filteredStores, setFilteredStores] = useState([]);
  const [broadcastToAligned, setBroadcastToAligned] = useState(false);
  const [broadcastToAll, setBroadcastToAll] = useState(false);

  const [getTemplate, setTemplate] = useState({ title: "" });
  const [allAvailableTime] = useState(arrOfZeroToFour);
  const [showDialog, setShowDialog] = useState(null);
  const [showPopupForPayException, setShowPopupForPayException] =
    useState(false);
  const [deleteShiftTemplate, setDeleteShiftTemplate] = useState([false, ""]);

  const [isRejectingShift, setIsRejectingShift] = useState(false);
  const [payExceptionNote, setPayExceptionNote] = useState("");
  const [whoIsApproving, setWhoIsApproving] = useState("");
  const [estimatedPrice, setIsEstimatedPrice] = useState("");
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [reasonForScore, setReasonForScore] = useState("excess_drivers");
  const dates = useSelector((state) => state.modal.order.id);
  const [selectTemplates, setSelectTemplates] = useState([]);
  const [formData, setFormData] = useState({});
  const [payoutType, setPayOutType] = useState("");
  const [state, setState] = useState([
    {
      startDate: dates?.state && dates?.state[0]?.startDate,
      endDate: dates?.state && dates?.state[0]?.startDate,
      key: "selection",
    },
  ]);
  const [payLoading, setPayLoading] = useState(false)
  const selectedMetro = useSelector((state) => state.metro.value);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { role } = currentUser || {};

  const [payDriver, { isLoading: payDriverLoding }] = useChangeStatusMutation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [withBonus, setWithBonus] = useState(false);
  const [showPodInfo, setShowPodInfo] = useState(false)
  const [podData, setPodData] = useState([]);
  const [podDataFetched, setPodDataFetched] = useState(false)

  const form = useForm({
    defaultValues: {
      metro: selectedMetro || "",
      shiftType: shiftTypeOption[0].value || "",
      availableTime: arrOfZeroToFour,
      dayDeliveryStart: null,
      dayDeliveryStartTo: null,
      isBroadcastToAligned: false,
      isBroadcastToAll: false,
      routeCode: "",
      estimatedShiftPayFrom: "",
      estimatedShiftPayTo: "",
      note: "",
      internalNote: "",
      price: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const metro = form.watch("metro");
  const { getValues, setValue, formState, control, handleSubmit } = form;
  const { refetch, modal } = actions;
  const { exceptionNote, from, price, status, transactionStatus, shiftType } = order || {};
  const { dwTransferUrl, driver } = order || {};

  const refreshData = useCallback(
    () => refetch.setIsDataRefetch(true),
    [refetch]
  );
  const closeModal = useCallback(() => modal.closeOrder(), [modal]);

  useEffect(() => {
    if (role === "metro_manager") {
      let metro_manager_metros = JSON.parse(localStorage.getItem("user_metro"));
      const res = shiftTemplates?.filter((allTemplates) =>
        metro_manager_metros?.includes(allTemplates?.metro)
      );
      setSelectTemplates(res);
    } else {
      setSelectTemplates(shiftTemplates);
    }
  }, [role, shiftTemplates]);

  useEffect(() => {
    const error = updateError || createError;
    setGeneralError(
      error
        ? {
          message:
            error.message ||
            error.error ||
            (error.data && error.data.message) ||
            error.data,
        }
        : null
    );
  }, [updateError, createError]);

  useEffect(() => {
    if (order?.error) sendErrorMessage(order?.message)
    if (!order || order?.error) return;
    Object.entries(order).forEach(([name, value]) => setValue(name, value));
    const {
      estimatedShiftPayFrom,
      estimatedShiftPayTo,
      dayDeliveryStart,
      availableTime,
    } = order || {};
    setValue("from", from?._id || "");
    setValue("shiftType", shiftType || "");
    setValue(
      "estimatedShiftPayFrom",
      handleCurrencyPrice(estimatedShiftPayFrom)
    );
    setValue("estimatedShiftPayTo", handleCurrencyPrice(estimatedShiftPayTo));
    setValue("price", handleCurrencyPrice(price));
    setValue("availableTime", availableTime);
    dayDeliveryStart &&
      setValue(
        "dayDeliveryStart",
        moment(dayDeliveryStart).utc().format("MM-DD-yyyy")
      );
    const newPrice = getValues("price");
    setIsEstimatedPrice(newPrice || "");
    if (getValues("price")) setPriceError("");
  }, [order, id, from?._id, shiftType, price, getValues, setValue]);

  useEffect(() => {
    if (!getTemplate) {
      setTemplate({ title: "" });

      // setValue("availableTime", arrOfZeroToFour);
      setValue("from", "");
      setValue("shiftType", "");
      setValue("routeCode", "");
      setValue("estimatedShiftPayFrom", "");
      setValue("estimatedShiftPayTo", "");
      setValue("metro", "");
      setValue("startTime", "");
      setValue("numberOfShifts", "");
      setValue("estimatedShiftTimeFrom", "");
      setValue("estimatedShiftTimeTo", "");
      setValue("note", "");
      setValue("internalNote", "");
    } else {
      const { price, estimatedShiftPayFrom, estimatedShiftPayTo } =
        getTemplate || {};
      Object.entries(getTemplate).forEach(([name, value]) =>
        setValue(name, value)
      );
      const ESPF = handleCurrencyPrice(estimatedShiftPayFrom);
      // setValue("price", handleCurrencyPrice(price));
      !order?.availableTime && setValue("availableTime", arrOfZeroToFour);
      estimatedShiftPayFrom && setValue("estimatedShiftPayFrom", ESPF);
      estimatedShiftPayTo &&
        setValue(
          "estimatedShiftPayTo",
          handleCurrencyPrice(estimatedShiftPayTo)
        );
      getTemplate?.from && setValue("from", getTemplate?.from?._id);
      getTemplate?.shiftType && setValue("shiftType", getTemplate?.shiftType);
    }
  }, [getTemplate, setValue, getValues]);

  useEffect(() => {
    if (!stores) return;

    const { metro } = getValues();
    const availableStores = metro
      ? stores?.filter((store) => store?.metro === metro)
      : stores;


    setFilteredStores(availableStores);
    if (
      availableStores &&
      !availableStores?.find((store) => store._id === getValues().from)
    )
      setValue("from", null);
  }, [metro, stores, setValue, getValues]);

  useEffect(() => {
    if (showPodInfo && !podDataFetched) {
      fetchPodData()
    }
  }, [showPodInfo])

  const fetchPodData = async () => {
    const result = await getPodDataApi(id)

    setPodData(result?.data || [])
    setPodDataFetched(true)
  }

  const handleCustomRange = () => setShowDateRangePicker(!showDateRangePicker);

  const onSubmit = useCallback(
    async (data) => {
      if (order && id) {
        data.dayDeliveryStart = moment(data.dayDeliveryStart);
        data.dayDeliveryStart = data.dayDeliveryStart._i;
        data.dayDeliveryStartTo = null;
      }

      data.price = estimatedPrice;
      if (["rejected"].includes(data?.status)) data.driver = null;

      if (+estimatedPrice === +price || +estimatedPrice <= 300) {
        const result = await (id ? update(data) : create(data));
        if (!result.error) {
          sendSuccessMessage(id ? UPDATED : CREATED);
          actions.refetch.setIsDataRefetch(true);
          actions.modal.closeOrder();
          props?.id?.refetch && props?.id?.refetch();
        }
      } else {
        actions.modal.openPayoutWarning();
        setFormData(data)
        setPayOutType("save")
      }
    },
    [create, id, update, estimatedPrice, order]
  );

  const handlePayoutCancel = () => {
    actions.modal.closePayoutWarning();
    setIsEstimatedPrice(handleCurrencyPrice(price));
  }

  const handleReponse = () => {
    sendSuccessMessage(id ? UPDATED : CREATED);
    actions.refetch.setIsDataRefetch(true);
    actions.modal.closeOrder();
    actions.modal.closePayoutWarning();
  }

  const handlePayoutSubmit = async () => {
    if (payoutType === "save") {
      const result = await (id ? update(formData) : create(formData));
      if (!result.error) {
        handleReponse()
        props?.id?.refetch && props?.id?.refetch();
      }
    } else if (payoutType === "pay") {
      const data = getValues();
      const result = await update(data);
      if (!result.error) {
        handleReponse()
      }
    } else {
      handleSuperAdminPay()
      actions.modal.closePayoutWarning();
    }
  }

  const onCancel = useCallback(() => {
    if (!formState.isDirty || window.confirm(MSG.MODAL_CLOSE))
      modal.closeOrder();
  }, [formState.isDirty, modal]);

  const copyToClipboard = (url) => navigator.clipboard.writeText(url);

  const handleApproveOrder = useCallback(async () => {
    const result = await changeStatusForApproveOrderPayments({
      _id: id,
      exceptionNote: payExceptionNote,
    });


    const body = {
      type: "shiftpayment",
    };
    if (result && role === "admin") {
      await sendSlackMessage(body);
      actions.refetch.setIsDataRefetch(true);
    }
  }, [
    changeStatusForApproveOrderPayments,
    id,
    payExceptionNote,
    sendSlackMessage,
    role,
  ]);

  let oneToHundread = Array.from({ length: 100 }, (_, i) => i + 1);

  const DialogTitle = () => {
    const price = getValues("price");
    return (
      <React.Fragment>
        <RiFileTransferFill className="icon" />
        <span>
          Transfering <strong>${price}</strong> to{" "}
          <strong>
            {order.driver.firstName.concat(" ", order.driver.lastName)}
          </strong>
        </span>
      </React.Fragment>
    );
  };

  const PayExceptionTitle = () => {
    return <span>{isRejectingShift ? REJECTING_SHIFT : FINAL__PAYMENT}</span>;
  };

  const updateEstimatedShiftTimeFrom = (val) =>
    setValue("estimatedShiftTimeFrom", val);

  const updateEstimatedShiftTimeTo = (val) =>
    setValue("estimatedShiftTimeTo", val);

  const updateStartTime = (val) => setValue("startTime", val);

  const setIsBroadcastToAlignedValue = (e) => {
    setValue("isBroadcastToAligned", e.target.checked);
    setBroadcastToAligned(e.target.checked);

    if (e.target.checked !== false) {
      setValue("isBroadcastToAll", !e.target.checked);
      setBroadcastToAll(!e.target.checked);
    }
  };

  const setIsBroadcastToAllValue = (e) => {
    setValue("isBroadcastToAll", e.target.checked);
    setBroadcastToAll(e.target.checked);

    if (e.target.checked !== false) {
      setValue("isBroadcastToAligned", !e.target.checked);
      setBroadcastToAligned(!e.target.checked);
    }
  };

  const handlePrice = (e) => {
    const t = e.target.value;
    e.target.value =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
        : t;
  };

  const handleCurrency = (e) => {
    setInputFilter(
      document.getElementById("currencyTextBox"),
      function () {
        return /^-?\d*[.,]?\d{0,2}$/.test(e.target.value);
      },
      "Must be a currency value"
    );
    setIsEstimatedPrice(e.target.value);
    setValue("price", e.target.value);
    setGeneralError(null);
    setPriceError()
  };

  const handleChange = (value, data) => {
    setTemplate(data)
  };

  const checkForError = useCallback(
    (error) => (error ? errorHandlers(error) : closeModal()),
    [closeModal]
  );

  const checkStatusBeforePayment = async () => {
    if (status === "approved") {
      const { data: statusFromPayment, error } = await changeStatus({
        status: "pay",
        _id: id,
      });
      error && checkForError(error);
      return statusFromPayment;
    } else {
      const { data: statusFromPayment, error } =
        await changeStatusForApproveOrderPayments({
          _id: id,
        });
      // if (statusFromPayment?.transactionFailedReason) {
      //   sendErrorMessage(`${statusFromPayment?.transactionFailedReason}`)
      // }
      error && checkForError(error);
      props?.refetch && props?.refetch();
      return statusFromPayment;
    }
  };


  const handleSuperAdminPay = async () => {
    setWhoIsApproving("ROOT");
    setShowDialog(null);
    const data = getValues();
    const price = data?.price?.split(".");

    const isCheck =
      price[1] === "00"
        ? +price[0] === order?.price
        : +data.price === order?.price;

    if (!isCheck) await update(data);

    await checkStatusBeforePayment();
    refreshData();

    if (!isApproveOrderLoading) {
      sendSuccessMessage(id ? UPDATED : CREATED);
      actions.refetch.setIsDataRefetch(true);
      actions.modal.closeOrder();
      props?.id?.refetch && props?.id?.refetch();
    }

  };

  const handleRejectDriverShift = async () => {
    const rejectShiftBody = {
      status: "reject",
      _id: order._id,
      driverId: order?.driver?._id,
      reason: payExceptionNote,
      rejectType: reasonForScore,
    };
    const { data: result } = await changeStatus(rejectShiftBody);

    if (result?.status === "rejected") {
      sendSuccessMessage(SHIFT_REJECTED);
      setAssignedDriver(null);
      setIsRejectingShift(false);
      setPayExceptionNote("");
      refreshData();
    } else checkForError(result);
  };

  const handleStartDelivery = async () => {
    const m = moment(new Date());
    const minutes = m.hour() * 60 + m.minute();
    const todayDate = m.format("DD-MM-YYYY");

    const { data: result } = await changeStatus({
      status: "startDelivery",
      _id: order?._id,
      deliveryStartTime: `${todayDate}_${minutes}`,
    });

    if (result?.status === "delivery_started") {
      sendSuccessMessage(DELIVERY_STARTED);
      refreshData();
    } else checkForError(result?.error);
  };

  const handleCompleteOrder = async () => {
    const body = {
      status: "complete",
      _id: order?._id,
    };
    const result = await changeStatus(body);
    checkForSuccessStatus(result?.data, "complete");
  };

  const handleCloseRejectingModal = () => {
    setDeleteShiftTemplate([false, ""])
    setIsRejectingShift(false);
    setPayExceptionNote("");
  };

  const handleConfirmAction = async () => {
    const ids = [deleteShiftTemplate[1]]
    const result = await remove({
      ids,
      reason: payExceptionNote,
      cancelType: reasonForScore
    });

    const msg = CANCELLED_SINGLE;
    if (!result?.error && result?.data?.status === 200) sendSuccessMessage(msg);
    setIsRejectingShift(false);
    setPayExceptionNote("");
    refreshData();
    setDeleteShiftTemplate([false, []])
  };

  const checkForSuccessStatus = useCallback(
    (result, statusText) => {
      if (result?.status === statusText) {
        sendSuccessMessage(SHIFT_COMPLETED);
        refreshData();
      } else checkForError(result?.data?.error || result?.error || result);
    },
    [checkForError, refreshData]
  );

  const SAVE__BTN = (
    !isOrderLoading && (
      <button
        className="btn btn_accent"
        disabled={isUpdating || isCreating || isOrderLoading || isStoreLoading}
        onClick={handleSubmit(onSubmit)}>
        Save
      </button>)
  );

  const REJECT_SHIFT = useMemo(() => {
    return (
      <RejectShift
        isRejectingShift={isRejectingShift}
        payExceptionNote={payExceptionNote}
        setPayExceptionNote={(e) => setPayExceptionNote(e.target.value)}
        setReasonForScore={(value) => setReasonForScore(value)}
        reasonForScore={reasonForScore}
      />
    );
  }, [isRejectingShift, payExceptionNote, reasonForScore]);


  const openExceptions = async () => {
    const id = await props.id.item._id;
    actions.modal.openException(id);
  }


  const pay = async () => {
    const payresult = await payDriver({
      status: withBonus ? 'pay' : 'directpay',
      _id: props?.id?.item?._id,
    });
    if (!payDriverLoding) {
      if (payresult?.data?.message) {
        sendErrorMessage(`${payresult?.data?.message}`)
      }
    }
  }

  const tooltipShow = (elem) => {
    return (
      <div className="d-flex flex-column " style={{ fontSize: "12px", color: "white", gap: "5px" }}>
        <div>Parcel Tracking Number : <span style={{ fontSize: "11px " }}>{elem?.ParcelTrackingNumber}</span></div>
        <div>Shipment Tracking Number : <span style={{ fontSize: "11px" }}>{elem?.ShipmentTrackingNumber}</span></div>
        <div>Event Date Time : <span style={{ fontSize: "11px" }}>
          {elem?.EventDateTime && moment(elem?.EventDateTime).format("MM-DD-YYYY hh:mm:ss")}
        </span>
        </div>
      </div>
    )
  }

  return (

    <React.Fragment>
      <Modal
        open
        onClose={onCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className={`modal ${!isStoreLoading && !isOrderLoading ? "after-loading" : "when-loading"
            }`}
        >
          {showDialog && (
            <ConfirmDialogModal
              title={<DialogTitle />}
              bodyText={"Are you sure?"}
              isOpen
              onClose={() => setShowDialog(null)}
              confirmAction={handleSuperAdminPay}
              modalText="PAY"
            />
          )}
          {deleteShiftTemplate[0] && (
            <ConfirmDialogModal
              title="Write a reason for cancelling shift"
              bodyText={REJECT_SHIFT}
              isOpen
              onClose={handleCloseRejectingModal}
              confirmAction={handleConfirmAction}
              modalText="CANCEL__SHIFT"
              isRejectingShift={isRejectingShift}
              payExceptionNote={payExceptionNote}
              isLoading={isChangingStatus}
            />
          )}

          <div className="modal__header d-flex justify-content-between">
            <h2 className="modal__title">{title}</h2>
            {id && (
              <button
                className="btn btn_block btn_accent customized-details-btn w-auto"
                onClick={() => modal.openOrderLogs(id)}
                type="button"
              >
                Logs
              </button>
            )}
          </div>
          <div className="modal__body">
            {!isOrderLoading && !isStoreLoading ? (
              <React.Fragment>
                <form>
                  {!id && (
                    <FieldSet
                      className="d-flex pb-0"
                      style={{
                        gap: "1%",
                      }}
                      body={
                        <React.Fragment>
                          <div className="form__item w-50">
                            <Label title="Select Template" />
                            {shiftTemplates && (
                              <Controller
                                control={control}
                                name="shiftTemplate"
                                render={({
                                  field: { onChange, value, ref },
                                  fieldState: { error },
                                }) => (
                                  <React.Fragment>
                                    <Autocomplete
                                      options={selectTemplates}
                                      sx={autocomplete}
                                      id="shiftTemplate"
                                      value={value}
                                      onChange={(value, data) =>
                                        handleChange(value, data)
                                      }
                                      ref={ref}
                                      renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                          {option.title}
                                        </Box>
                                      )}
                                      getOptionLabel={(option) => option.title}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Select"
                                          inputProps={{
                                            ...params.inputProps,
                                          }}
                                        />
                                      )}
                                    />
                                    <ErrorMessage error={error} />
                                  </React.Fragment>
                                )}
                              />
                            )}
                          </div>

                          <div className="form__item">
                            <div>
                              <Label title="No Of Shifts" />
                            </div>
                            <Controller
                              control={control}
                              name="numberOfShifts"
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <React.Fragment>
                                  <Select
                                    sx={{ ...select, ...hoverAndFocusedStyle }}
                                    style={{
                                      height: "50%",
                                      width: "100%",
                                    }}
                                    id="numberOfShifts"
                                    value={value || ""}
                                    onChange={(e) => onChange(e.target.value)}
                                    className={error && "MuiSelect-root-invalid"}
                                  >
                                    {oneToHundread.map((item, index) => (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <ErrorMessage error={error} />
                                </React.Fragment>
                              )}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  )}

                  <div className="form__row">
                    <div
                      className="form__col form__col_height-flex"
                      style={{ width: "45%" }}
                    >
                      <fieldset className="w-100">
                        <div className="form__item">
                          <Label title="Shift Type" />
                          <Controller
                            control={control}
                            name="shiftType"
                            render={({
                              field: { onChange, value, ref },
                              fieldState: { error },
                            }) => (
                              <React.Fragment>
                                <Select
                                  sx={{
                                    ...hubSelectStyle,
                                    ...hoverAndFocusedStyle,
                                  }}
                                  style={{ width: "100%" }}
                                  className={error && "MuiSelect-root-invalid"}
                                  id="shiftType"
                                  value={value || ""}
                                  onChange={(e) => onChange(e.target.value)}
                                >
                                  {shiftTypeOption.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {item.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <ErrorMessage error={error} />
                              </React.Fragment>
                            )}
                          />
                        </div>
                        <div className="form__item">
                          <Label title="Metro" />
                          <Controller
                            control={control}
                            name="metro"
                            render={({
                              field: { onChange, value, ref },
                              fieldState: { error },
                            }) => (
                              <React.Fragment>
                                <MetroSelect
                                  value={value}
                                  onChange={(e, data) => onChange(data)}
                                  ref={ref}
                                  isError={error && true}
                                  style={{ width: "100%" }}
                                  disabled={["rejected"].includes(order?.status)}

                                />
                                <ErrorMessage error={error} />
                              </React.Fragment>
                            )}
                          />
                        </div>
                        <div className="form__col form__col_height-flex">
                          <Label title="Hub" htmlFor="from" />
                          <div className="form__item">
                            <Controller
                              control={control}
                              name="from"
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <React.Fragment>
                                  <Select
                                    placeholder="Select hub"
                                    sx={{
                                      ...hubSelectStyle,
                                      ...hoverAndFocusedStyle,
                                    }}
                                    id="from"
                                    style={{ width: "100%" }}
                                    value={value || ""}
                                    onChange={(e) => onChange(e.target.value)}
                                    className={error && "MuiSelect-root-invalid"}
                                    disabled={["rejected"].includes(order?.status)}
                                    input={
                                      <OutlinedInput
                                        id="outlined-age-simple"
                                        classes={error && outlinedInputClasses}
                                      />
                                    }
                                  >
                                    <MenuItem key={0} value={""}>
                                      [no hub]
                                    </MenuItem>

                                    {checkArrForMapping(filteredStores) &&
                                      filteredStores?.map((store) => (
                                        <MenuItem
                                          key={store._id}
                                          value={store._id}
                                        >
                                          {store.name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  <ErrorMessage error={error} />
                                </React.Fragment>
                              )}
                            />
                          </div>
                        </div>
                        {order ? (
                          <div className="form__col form__col_height-flex">
                            <Label title="Route Date" htmlFor="date-picker" />
                            <div className="form__item">
                              <Controller
                                control={control}
                                name="dayDeliveryStart"
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      value={new Date(value)}
                                      onChange={(date) => {
                                        onChange(date);
                                      }}
                                      disabled={["rejected"].includes(order?.status)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ width: "100%" }}
                                          id="date-picker"
                                        />
                                      )}
                                    />
                                    <ErrorMessage error={error} />
                                  </LocalizationProvider>
                                )}
                              />
                            </div>
                          </div>
                        ) : (
                          <RouteDateRange
                            form={form}
                            showDateRangePicker={showDateRangePicker}
                            fromDate={state && state[0]?.startDate}
                            toDate={state && state[0]?.endDate}
                            state={state}
                            handleCustomRange={handleCustomRange}
                            handleCloseDtPickr={() =>
                              setShowDateRangePicker(false)
                            }
                            handleChangeDtRange={(item) => setState(item)}
                          />
                        )}
                        <div className="form__item">
                          <Description
                            dlClass="term_inline"
                            title="Status:"
                            value={
                              order && handlePaymentStatusForDetails(order?.status, order?.price, order?.isRequestedPayments, order?.isWeeklyPayments, path)
                            }
                            obj={obj}
                          />
                          {status === "assigned" && (
                            <>
                              <div className="reject__driver__container">
                                {(firstName ||
                                  lastName ||
                                  email ||
                                  assignedDriver) && (
                                    <p className="driver__name__text">{`${handledriverName(
                                      firstName,
                                      lastName,
                                      email
                                    )}`}</p>
                                  )}
                                <button
                                  className="btn bg-danger reject__btn"
                                  type="button"
                                  disabled={isChangingStatus}
                                  onClick={() => {
                                    setDeleteShiftTemplate([true, order?._id])
                                    setIsRejectingShift(true);
                                    setPayExceptionNote("");
                                    setReasonForScore("excess_drivers");
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                              {driver?.lastLoginTime && (
                                <DeviceAndLoginInfo order={order} />
                              )}
                            </>
                          )}

                          <div className="order-status">
                            {status === "assigned" && (
                              <button
                                className="btn btn_accent mt-2 customized-details-btn"
                                type="button"
                                disabled={isChangingStatus}
                                onClick={handleStartDelivery}
                              >
                                Start delivery
                              </button>
                            )}
                            {status === "delivery_started" && (
                              <div className="d-flex" style={{ gap: "1.5rem" }}>
                                <button
                                  className="btn btn_accent mt-2 customized-details-btn"
                                  type="button"
                                  disabled={isChangingStatus}
                                  onClick={() =>
                                    handleCompleteOrder("complete")
                                  }
                                >
                                  Complete
                                </button>

                                <button
                                  className="btn btn_accent mt-2 btn__sm"
                                  type="button"
                                  disabled={isChangingStatus}
                                  onClick={() => {
                                    modal.openReason({
                                      obj: order,
                                      title: "SPLIT",
                                    });
                                  }}
                                >
                                  Split
                                </button>
                              </div>
                            )}

                            <div className="d-flex" style={{ gap: "3%" }}>
                              {order && (role && role !== "metro_manager") &&
                                (status === "complete" || status === "approved" ||
                                  (props.id.path.includes("/payments") &&
                                    status === "approved")) && (
                                  <div
                                    className="d-flex"
                                    style={{
                                      width: '100%',
                                      gap: "3%",
                                      flexWrap: 'wrap'
                                    }}>

                                    {/* {(status !== "approved" && (props.id.path.includes("/payments") || !order?.exceptionNote) && (order.bonusPrice && order?.bonusPrice !== 0)) ? (

                                        <button
                                          className="btn btn_accent btn__sm"
                                          type="button"
                                          disabled={
                                            isChangingStatus ||

                                            isApproveOrderLoading
                                          }

                                          onClick={() => {
                                            const message = "Please enter amount";
                                            if (estimatedPrice?.length <= 0)
                                              setPriceError({ message });
                                            else handleSuperAdminPay();
                                           
                                          }}
                                        >
                                          Approve
                                          {isApproveOrderLoading &&
                                             whoIsApproving !== "ADMIN" && ( 
                                               <> 
                                                <CircularProgress
                                                  style={customizedCircularProgress}
                                                />
                                               </> 
                                             )} 
                                        </button>
                                        
                                      ):null} */}

                                    {/* {(status !== "approved" && (props.id.path.includes("/payments") || !order?.exceptionNote) && (order.bonusPrice && order?.bonusPrice !== 0)) ? ( */}
                                    {(status !== "approved" && (props.id.path.includes("/payments") || !order?.exceptionNote)) ? (
                                      <Box sx={{ position: 'relative' }}>
                                        <Button
                                          variant="contained"
                                          className="btn btn_accent btn__sm"
                                          disabled={
                                            isChangingStatus ||

                                            isApproveOrderLoading
                                          }

                                          onClick={() => {
                                            const message = "Please enter amount";
                                            if (+estimatedPrice <= 0)
                                              setPriceError({ message });
                                            else if (+estimatedPrice === +price || +estimatedPrice <= 300) {
                                              handleSuperAdminPay();
                                            } else {
                                              actions.modal.openPayoutWarning();
                                              setPayOutType("approve")
                                            }

                                          }}
                                        >
                                          Approve
                                          {isApproveOrderLoading &&
                                            whoIsApproving !== "ADMIN" &&
                                            <div style={{ width: '25px' }}></div>
                                          }
                                        </Button>
                                        {isApproveOrderLoading &&
                                          whoIsApproving !== "ADMIN" && (
                                            <CircularProgress
                                              size={18}
                                              sx={{
                                                color: '#fff',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '83%',
                                                marginTop: '-8px',
                                                marginLeft: '-10px',
                                              }}
                                            />
                                          )}
                                      </Box>
                                    ) : null}

                                    {/* {((status == "complete" && !order.bonusPrice && role === 'root' && (props.id.path.includes("/shifts") || props.id.path.includes("/payments"))) */}
                                    {((status == "approved" && role === 'root' && props.id.path.includes("/payments")) ||
                                      (status == "approved" && role === 'root' && order.transactionFailedReason && props.id.path.includes("/shifts"))) && (
                                        <Box sx={{ position: 'relative' }}>
                                          <Button
                                            variant="contained"
                                            className="btn btn_accent btn__sm"
                                            disabled={payDriverLoding || payLoading}

                                            onClick={async () => {
                                              const message = "Please enter amount";
                                              if (+estimatedPrice <= 0)
                                                setPriceError({ message });
                                              else if (+estimatedPrice === +price || +estimatedPrice <= 300) {
                                                setShowConfirm(true);
                                              } else {
                                                actions.modal.openPayoutWarning();
                                                setPayOutType("pay")
                                              }
                                            }}
                                          >
                                            Pay
                                          </Button>
                                          {(payLoading || payDriverLoding) && (
                                            <CircularProgress
                                              size={18}
                                              sx={{
                                                color: '#fff',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '83%',
                                                marginTop: '-8px',
                                                marginLeft: '-10px',
                                              }}
                                            />
                                          )}
                                          {/* {
                                              order.bonusPrice ? (
                                              <>
                                                <Checkbox className="with_bonus_check" checked={withBonus} onChange={() => setWithBonus(!withBonus)} />
                                                <span style={{ marginLeft: '-5px' }}>With Bonus</span>
                                              </>
                                              ):null} */}
                                        </Box>
                                        // <button
                                        //   className="btn btn_accent btn__sm"
                                        //   type="button"
                                        //   disabled={payDriverLoding}
                                        //   onClick={() => {
                                        //     const message = "Please enter amount";
                                        //     if (estimatedPrice?.length <= 0)
                                        //       setPriceError({ message });
                                        //     else setShowConfirm(true);
                                        //   }}
                                        // >
                                        //   Pay
                                        //   {/* {payDriverLoding && */}
                                        //     {/* ( */}
                                        //       <>
                                        //         &nbsp;
                                        //         <CircularProgress
                                        //           style={customizedCircularProgress}
                                        //         />
                                        //       </>
                                        //     {/* )} */}

                                        // </button>
                                      )}


                                    <div style={{ display: 'flex', gap: '3%' }}>
                                      {(!order?.exceptionNote && status !== "approved") && (
                                        <button
                                          className="btn btn_accent btn__sm"
                                          type="button"
                                          onClick={openExceptions}
                                          style={{ whiteSpace: "nowrap" }}>
                                          {/* {`${(order.bonusPrice && order.bonusPrice !== 0) ? 'Approve ' : 'Pay '}with Exception`} */}
                                          {`Approve with Exception`}
                                        </button>
                                      )}

                                      {!["approved", "paid", "cancelled", "rejected"].includes(
                                        order?.status
                                      ) && obj && (
                                          <button
                                            className={`btn btn_accent ${status && status !== "complete" && "mt-2"
                                              } btn__sm ${status === "delivery_started" && "ml-3"
                                              }`}
                                            type="button"
                                            disabled={isChangingStatus}
                                            onClick={() => {
                                              const swapShiftBody = {
                                                obj: order,
                                                title: "SWAP__SHIFT",
                                              };
                                              modal.openReason(swapShiftBody);
                                            }}
                                          >
                                            Swap
                                          </button>
                                        )}
                                    </div>

                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        {status === "paid" && (
                          <>
                            {(order?.paymentmode !== "BRANCH" && dwTransferUrl) &&
                              <Description
                                title="Link to transaction:"
                                value={
                                  <>
                                    <a
                                      href={dwTransferUrl || ""}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <GoLinkExternal className="term__link-icon" />
                                      {dwTransferUrl || ""}
                                    </a>
                                    <span
                                      onClick={() =>
                                        copyToClipboard(dwTransferUrl || "")
                                      }
                                      title="copy to clipboard"
                                    >
                                      <FaClipboard className="order-copy-icon" />
                                    </span>
                                  </>
                                }
                              />
                            }

                            <Description
                              dlClass="term_inline"
                              title="Transaction Status:"
                              value={transactionStatus}
                              obj={obj}
                              mode={order?.paymentmode}
                            />
                          </>
                        )}
                        {
                          ((obj?.status === "complete" || obj?.status === "approved" || obj?.status === "paid") && obj?.shiftType === "DELIVERY") && (
                            <div className={`term mt-2 `}
                              style={{
                                fontSize: "18px",
                                color: getUnaccounted?.result?.unaccounted_for_count === 0 ? "green"
                                  : getUnaccounted?.result?.unaccounted_for_count > 0 ? "red" : "black"
                              }}>
                              <span className={`term__title `} >
                                Unaccounted For Packages:
                              </span>
                              <span className={`term__value ml-1`}>
                                {UnaccountedLoading ?
                                  <CircularProgress style={{ width: "15px", height: "15px" }} /> :
                                  getUnaccounted?.result?.unaccounted_for_count >= 0 ? getUnaccounted?.result?.unaccounted_for_count : getUnaccounted?.status == 200 ? "No result found" : getUnaccounted?.message}

                              </span>
                            </div>
                          )
                        }
                      </fieldset>
                    </div>

                    <fieldset style={{ width: "52%" }}>
                      <div>
                        <InputItem
                          title="Route Code:"
                          name="routeCode"
                          form={form}
                          type="text"
                          required
                          pattern="[a-zA-Z0-9]+"
                          disabled={["rejected"].includes(order?.status)}
                        />
                      </div>

                      <div className="d-flex" style={{ gap: "8.8%" }}>
                        <Label title="Estimated Shift Duration:" />
                        <Label title="Start Time:" />
                      </div>

                      <div className="d-flex" style={{ gap: "8%" }}>
                        <div className="d-flex">
                          <div>
                            <Controller
                              control={control}
                              name="estimatedShiftTimeFrom"
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <Hourpicker
                                  value={value}
                                  updateTime={updateEstimatedShiftTimeFrom}
                                  error={error}
                                  disabled={["rejected"].includes(order?.status)}
                                />
                              )}
                            />
                          </div>
                          <Label
                            title={"To"}
                            component="span"
                            style={formLabelStyle}
                          />
                          <div>
                            <Controller
                              control={control}
                              name="estimatedShiftTimeTo"
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <Hourpicker
                                  value={value}
                                  updateTime={updateEstimatedShiftTimeTo}
                                  error={error}
                                  disabled={["rejected"].includes(order?.status)}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div>
                          <Controller
                            control={control}
                            name="startTime"
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <Timepicker
                                value={value}
                                updateTravelTime={updateStartTime}
                                error={error}
                                disabled={["rejected"].includes(order?.status)}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="d-flex" style={{ gap: "11%" }}>
                        <Label title="Estimated Shift Pay ($):" />
                        <Label title="Finalized Payment Amount ($):" />
                      </div>

                      <div className="d-flex w-100" style={{ gap: "8%" }}>
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "40%" }}
                        >
                          <InputItem
                            title=""
                            name="estimatedShiftPayFrom"
                            form={form}
                            type="text"
                            onInput={(e) => handlePrice(e)}
                            disabled={["rejected"].includes(order?.status)}
                          />
                          <Label
                            style={{ ...formLabelStyle, marginBottom: "1.5rem" }}
                            component="span"
                            title="To"
                          />
                          <InputItem
                            title=""
                            name="estimatedShiftPayTo"
                            form={form}
                            onInput={(e) => handlePrice(e)}
                            disabled={["rejected"].includes(order?.status)}
                          />
                        </div>
                        <div style={{ width: "55%" }}>
                          <input
                            id="currencyTextBox"
                            onChange={(e) => handleCurrency(e)}
                            name="price"
                            className="form__input"
                            autoComplete="off"
                            value={estimatedPrice}
                            disabled={
                              ["paid", "rejected"].includes(order?.status) || role === "admin"
                            }
                          />
                          <ErrorMessage error={priceError} />
                        </div>
                      </div>

                      {order?.OAI_price ? (<div style={{ paddingBottom: '15px' }}>
                        <div className="d-flex" style={{ gap: "25px" }}>
                          <span style={{ width: '50%' }}>
                            <Label title="OAI Amount ($):" />
                          </span>
                          <span style={{ width: '50%' }}>
                            <Label title="Total Amount ($):" />
                          </span>
                        </div>
                        <div style={{ display: 'flex', gap: "25px" }}>
                          <span style={{ width: '50%' }}>
                            <input
                              className="form__input"
                              disabled
                              value={(order?.OAI_price).toFixed(2) || 0}
                            />
                          </span>
                          <span style={{ width: '50%' }}>
                            <input
                              className="form__input"
                              disabled
                              value={(order?.totalPrice).toFixed(2) || 0}
                            />
                          </span>
                        </div>
                      </div>
                      ) : null}

                      <InputItem title="Zones/Notes" name="note" form={form} />
                      <InputItem
                        title="Internal Note"
                        name="internalNote"
                        form={form}
                      />
                    </fieldset>
                  </div>

                  {exceptionNote && (
                    <fieldset>
                      <legend>
                        <strong>
                          <em>Exception Note</em>
                        </strong>
                      </legend>
                      <p>
                        <strong>{exceptionNote || ""}</strong>
                      </p>
                    </fieldset>
                  )}
                  <fieldset>
                    <legend>Timetable</legend>
                    <div className="form__item">
                      <Label title="Shift Window" component={"span"} />
                      <div className="form__inline-items">
                        {allAvailableTime?.map((timeIndex) => {
                          const { title, range } = Times[timeIndex] || {};
                          return (
                            <label className="form__check-label" key={timeIndex}>
                              <Controller
                                control={control}
                                name="availableTime"
                                render={({ field: { onChange, value } }) => (
                                  <input
                                    type="checkbox"
                                    name={title}
                                    id={title}
                                    checked={!!value.includes(timeIndex)}
                                    onChange={() =>
                                      onChange(toggleTime(value, timeIndex))
                                    }
                                    value={timeIndex}
                                    disabled={["rejected"].includes(order?.status)}
                                  />
                                )}
                              />
                              {title}
                              <span className="form__check-label-add ml-1">
                                {` ${range}`}
                              </span>
                            </label>
                          );
                        })}
                      </div>
                    </div>

                    {!id && (
                      <>
                        <input
                          type="checkbox"
                          name="isBroadcastToAligned"
                          id="isBroadcastToAligned"
                          checked={broadcastToAligned}
                          onChange={(e) => setIsBroadcastToAlignedValue(e)}
                        />
                        <Label
                          title={"Send Mobile Notifications To Aligned Drivers? "}
                          htmlFor="isBroadcastToAligned"
                        />
                        <br></br>
                        <input
                          type="checkbox"
                          name="isBroadcastToAll"
                          id="isBroadcastToAll"
                          checked={broadcastToAll}
                          onChange={(e) => setIsBroadcastToAllValue(e)}
                        />
                        <Label
                          title={"Send Mobile Notifications To All Drivers? "}
                          htmlFor="isBroadcastToAll"
                        />
                      </>
                    )}
                  </fieldset>

                  <div className="form__item form__item_summary form__item_half-margin">
                    <ErrorMessage error={generalError} />
                  </div>
                </form>

                {arrForAssignDriver.includes(order?.status) && (
                  <FieldSet
                    title="Driver"
                    body={
                      <AssignDriver
                        order={order}
                        onClick={(driver) => setAssignedDriver(driver)}
                        form={form}
                        driverId={driverId}
                      />
                    }
                  />
                )}

                {
                  (obj?.status === "approved" || obj?.status === "complete" || obj?.status === "paid" || obj?.status === "delivery_started") && (
                    <MuiAccordion expanded={showPodInfo} onChange={() => setShowPodInfo(!showPodInfo)}>
                      <MuiAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        PODs Info
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        {
                          isPodLoading ? (
                            <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "200px" }}>
                              <CircularProgress />
                            </div>
                          ) : (
                            podData?.length > 0 ? (
                              podData?.map((elem, index) => {
                                return (
                                  <div className="d-flex align-items-center" key={index}
                                    style={{ gap: "16px", padding: "12px 0", borderTop: index !== 0 ? "1px dashed #dadada" : "" }}
                                  >
                                    <p style={{ fontSize: "16px", width: "33%", wordBreak: "break-word" }}>{elem?.ParcelTrackingNumber}</p>
                                    <div className="w-100">
                                      <Grid container spacing={2}>
                                        {
                                          elem?.data?.map((item, id) => {
                                            return (
                                              <Grid item sm={4} md={3} lg={3} key={id}>
                                                <Tooltip title={tooltipShow(item)} arrow placement="bottom" >
                                                  <img src={item?.PodUrl} alt="pod" width="100%" height="130px"
                                                    style={{ cursor: "pointer", borderRadius: "0.625rem" }}
                                                    onClick={() => actions.modal.openPodsModal(item)} />
                                                </Tooltip>
                                              </Grid>
                                            )
                                          })
                                        }
                                      </Grid>
                                    </div>
                                  </div>
                                )
                              })
                            ) : (
                              <div className="text-center w-100" style={{ padding: "16px 0" }}>
                                {podData?.error ? podData?.message || "No result found" : "No result found"}
                              </div>
                            )
                          )
                        }
                      </MuiAccordionDetails>
                    </MuiAccordion>
                  )
                }
              </React.Fragment>
            ) : (
              <div className="loading-wrapper">
                <CircularProgress />
              </div>
            )}
          </div>
          <div className="modal__footer">
            <button className="btn" onClick={onCancel}>
              Cancel
            </button>
            {order
              ? !["cancelled", "paid"].includes(order?.status) && SAVE__BTN
              : SAVE__BTN}
          </div>
        </div>
      </Modal>

      {showConfirm && (
        <ConfirmDialogModal
          bodyText={
            "Pay for this single orders?"
          }
          isOpen
          onClose={() => setShowConfirm(false)}
          confirmAction={async () => {
            if (+estimatedPrice !== +price && +estimatedPrice <= 300) {
              const data = getValues();
              setPayLoading(true)
              await update(data);
              setShowConfirm(false);
              await pay();
              actions.refetch.setIsDataRefetch(true);
              setPayLoading(false)
            } else {
              setShowConfirm(false);
              pay();
            }
          }}
          modalText="PAY"
          isLoading={payLoading}
        />
      )}

      {payoutWarning.open && <PayoutWarning id={payoutWarning.id} amount={estimatedPrice} onCancel={handlePayoutCancel} handlePayoutSubmit={handlePayoutSubmit} />}
    </React.Fragment>
  );
};

export default CustomModal;

const Label = ({ component, title, className, htmlFor, style }) => {
  const CustomizeComponent = component || "label";
  return (
    <CustomizeComponent
      style={style || null}
      className={`form__label ${className || ""}`}
      htmlFor={htmlFor || ""}
    >
      {title}
    </CustomizeComponent>
  );
};

const Description = ({ title, value, dlClass, dtClass, ddClass, obj, mode }) => (
  <dl className={`term ${dlClass || ""}`}>
    <dt className={`term__title ${dtClass || ""}`}>{obj && title || ""}</dt>
    <dd className={`term__value ${ddClass || ""}`}>
      {mode ? `${value} (${mode})` : value || ""}
    </dd>
  </dl>
);

const FieldSet = ({ title, body, className, style }) => {
  return (
    <fieldset className={className || ""} style={style || null}>
      <legend>{title}</legend>
      {body || ""}
    </fieldset>
  );
};

// {/* <div className="form__item">
//             <span className="form__label" htmlFor="login">Status</span>
//             <div className="form__inline-items">
//               {statuses.map((item,index) => (
//                 <label className="form__check-label" key={index}>
//                   <input type="radio" name={item.title} id={item.title} checked={item.checked} onChange={() => handleCheckStatus(index)} />
//                   {item.title}
//                 </label>
//               ))}
//             </div>
//             <div className="form__msg form__msg_invalid">This field is empty</div>
//             {currentStatus}
//             <br/>
//             {currentStatusIndex}
//           </div> */}
