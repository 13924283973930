import { CircularProgress, MenuItem, Modal, Select } from "@mui/material";
import { actions } from "../../../redux/store/store";
import { useUpdateDriverMutation } from "../../../api/drivers";
import { sendSuccessMessage } from "../../../constants/extras/sendSuccessMsg";
import { checkForError } from "../../../constants/extras/errorHandlers";
import { Controller, useForm, useWatch } from "react-hook-form";
import VehicleData from "../../../util/vehicles"
import { useMemo } from "react";

const CAR_TYPES = [
  {
    title: 'Standard (e.g. 4 door sedan)',
    id: "0",
  },
  {
    title: 'Large (e.g. 4 door SUV, van)',
    id: "1",
  },
];

const carBrands = Object.keys(VehicleData);
const currentYear = new Date().getFullYear()
const last16Years = Array.from({ length: 16 }, (_, i) => currentYear - i);

function VehicleModal({ id }) {
  const { driver } = id;
  const carType = driver?.carType;
  const { year, brand, model } = driver?.vehicle || {};

  const [updateDriver, { isLoading: isUpdatingDriver }] = useUpdateDriverMutation();

  const form = useForm({
    defaultValues:
    {
      carType: carType?.id || null,
      vehicle: {
        year: year || "",
        brand: brand || "",
        model: model || "",
      }
    },
  });

  const { formState: { isDirty } } = form;
  const { vehicle } = useWatch({ control: form.control });
  const modelsForSelectedBrand = useMemo(() => carBrands.includes(vehicle.brand) ? VehicleData[vehicle.brand] : [], [form.formState])

  const onSubmit = async () => {
    const result = await updateDriver({
      _id: driver._id,
      ...form.getValues(),
      carType: CAR_TYPES.find(item => item.id === form.getValues().carType),
    });
    if (!result?.error) {
      sendSuccessMessage(`Schedule updated successfully`);
      actions.refetch.setIsDataRefetch(true);
    } else {
      checkForError(result);
    }
    actions.modal.closeVehicle();
  };
  return (
    <Modal
      open
      onClose={() => actions.modal.closeVehicle()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`modal after-loading`} style={{ maxWidth: "36.875rem" }}>
        <div className="modal__header">
          <h2 className="modal__title">{`Update Car/Vehicle`}</h2>
        </div>
        <div className="modal__body">

          { /* Car Brand */ }
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <span style={{ width: '100px' }} className="fw-600">Brand: </span>
            <div style={{ flexGrow: 1, minWidth: 0 }}>
              <Controller
                control={form.control}
                name="vehicle.brand"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Select
                    sx={{
                      height: "3rem",
                      marginTop: "5px",
                      marginBottom: "8px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid #dadada",
                        borderRadius: "0.375rem",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#a0a293",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#a0a293",
                      }
                    }}
                    style={{ width: "100%" }}
                    className={error && "MuiSelect-root-invalid"}
                    id="shiftType"
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value)}
                  >
                    {carBrands.map((brand, index) => (
                      <MenuItem key={index} value={brand}>
                        {brand}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          </div>

          {/* Car Model */}
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <span style={{ width: '100px' }} className="fw-600">Model: </span>
            <div style={{ flexGrow: 1, minWidth: 0 }}>
              <Controller
                control={form.control}
                name="vehicle.model"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    sx={{
                      height: "3rem",
                      marginTop: "5px",
                      marginBottom: "8px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid #dadada",
                        borderRadius: "0.375rem",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#a0a293",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#a0a293",
                      }
                    }}
                    style={{ width: "100%" }}
                    value={value || ""}
                    onChange={onChange}
                  >
                    {modelsForSelectedBrand.map((model, index) => (
                      <MenuItem key={index} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          </div>

          {/* Car Year */}
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <span style={{ width: '100px' }} className="fw-600">Year: </span>
            <div style={{ flexGrow: 1, minWidth: 0 }}>
              <Controller
                control={form.control}
                name="vehicle.year"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    sx={{
                      height: "3rem",
                      marginTop: "5px",
                      marginBottom: "8px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid #dadada",
                        borderRadius: "0.375rem",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#a0a293",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#a0a293",
                      }
                    }}
                    style={{ width: "100%" }}
                    value={value || ""}
                    onChange={onChange}
                  >
                    {last16Years.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          </div>

          {/* Car Type */}
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <span style={{ width: '100px' }} className="fw-600">Type: </span>
            <div style={{ flexGrow: 1, minWidth: 0 }}>
              <Controller
                control={form.control}
                name="carType"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <Select
                    sx={{
                      height: "3rem",
                      marginTop: "5px",
                      marginBottom: "8px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid #dadada",
                        borderRadius: "0.375rem",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#a0a293",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#a0a293",
                      }
                    }}
                    style={{ width: "100%" }}
                    className={error && "MuiSelect-root-invalid"}
                    id="shiftType"
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value)}
                  >
                    {CAR_TYPES.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          </div>

        </div>
        <div className="modal__footer">
          <button className="btn" onClick={() => actions.modal.closeVehicle()}>
            Cancel
          </button>
          <div style={{ position: 'relative' }}>
            <button
              className="btn btn_accent"
              onClick={onSubmit}
              disabled={isUpdatingDriver || !isDirty}
            >
              OK
            </button>
            {isUpdatingDriver && (
              <CircularProgress size={18}
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  top: '50%',
                  left: '83%',
                  marginTop: '-8px',
                  marginLeft: '-10px',
                }} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default VehicleModal;