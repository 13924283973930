import moment from "moment";
import { convertToCapitalize } from "../extras/convertToCapitalize";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);

export const headers = [
  { label: "Transaction Date", key: "transactionDate" },
  { label: "Name", key: "name" },
  { label: "BTUserId", key: "userBTId" },
  { label: "Metro", key: "metro" },
  { label: "Amount", key: "amount" },
  { label: "OAI Price", key: "OAI_price" },
  { label: "Bonus Price", key: "bonusPrice" },
  { label: "Transaction ID", key: "transactionId" },
  { label: "Payment Type", key: "paymentType" },
  { label: "Notes", key: "note" },
  { label: "Method", key: "paymentmode" },
  { label: "Shift Date", key: "shiftDate" },
  { label: "Reason", key: "reason" },
  { label: "Status", key: "status" },
];

export const header = [
  { label: "Transaction Date", key: "transactionDate" },
  { label: "Name", key: "name" },
  { label: "BTUserId", key: "userBTId" },
  { label: "Metro", key: "metro" },
  { label: "Amount", key: "amount" },
  { label: "OAI Price", key: "OAI_price" },
  { label: "Transaction ID", key: "transactionId" },
  { label: "Payment Type", key: "paymentType" },
  { label: "Notes", key: "note" },
  { label: "Method", key: "paymentmode" },
  { label: "Shift Date", key: "shiftDate" },
  { label: "Reason", key: "reason" },
  { label: "Status", key: "status" },
];

function paymentTypeReturn(data){
  if(data?.bonusPrice){
    return 'Weekly Payment'
  }

  if(data?.isRequestedPayments){
    return 'Instant payment'
  }

  if(data?.exceptionNote){
    return 'Payed with exception'
  }

  return 'Shift Payment'
}


export function transactionReport(filteredTransactionsData) {
  const transactionsReportListData = [];
  const getStatusText = (status) => {
    if (status === "completed") {
      return "Processed";
    } else if (status === "failed") {
      return "Failed";
    } else if (status === "pending" || status === "scheduled") {
      return "Pending"
    } else if (status === "canceled") {
      return "Cancelled"
    }
  };
  if (filteredTransactionsData && filteredTransactionsData.length) {
    for (let i = 0; i < filteredTransactionsData.length; i++) {
      let data = filteredTransactionsData[i];
      const {
        createdAt,
        name,
        userBTId,
        metro,
        amount,
        order,
        transactionId,
        note,
        shiftDate,
        status,
        reason,
        payment,
        paymentmode,
      } = data;
      transactionsReportListData[i] = {
        transactionDate: `${createdAt ? moment(createdAt).format("MM-DD-YYYY hh:mm A") : "-"
          }`,
        name: `${name ? name : "-"} `,
        userBTId: `${userBTId ? userBTId : "-"} `,
        metro: `${metro ? metro : "-"} `,
        amount: ` ${amount ? "$".concat(amount.toFixed(2)) : "$00"}`,
        OAI_price: ` ${order?.OAI_price ? "$".concat(order.OAI_price.toFixed(2)) : "$00"}`,
        bonusPrice: ` ${order?.bonusPrice ? "$".concat(order?.bonusPrice.toFixed(2)) : "$00"}`,
        transactionId: `${transactionId ? transactionId : "-"}`,
        paymentType: `${order ? paymentTypeReturn(order) : payment === 'adhocpayment' ? 'Adhoc Payment' : 'Shift Payment'}`,
        note: `${note ? note : "-"}`,
        paymentmode: `${paymentmode || "DWOLLA"}`,
        shiftDate: `${shiftDate ? moment(shiftDate.toString()).format("MM-DD-YYYY") : "-"
          }`,
        reason: `${reason ? reason : "-"} `,
        status: `${paymentmode === "BRANCH" ? getStatusText(status.toLowerCase()) : convertToCapitalize(status)}`,
      };
    }
  }
  transactionsReportListData.unshift({ transactionDate: `This report based on "${timeZone}  UTC ${utc}" Timezone ` })
  return transactionsReportListData;
}
