import moment from "moment";
import { getRoute } from "../../util/time";
import { handleStatus } from "../extras/convertToCapitalize";
import {
  handleEstimatedShiftPay,
  handleEstimatedShiftTime,
  handlePrice,
} from "../extras/handleShiftPayAndTime";
import { cancelType } from "../extras/rewording";
import { handlePaymentStatus } from "../extras/handlePaymentStatus";
import { statusList } from "../variables/arrays";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const utc = moment().format().slice(19);

export const headers = [
  { label: "Metro", key: "metro" },
  { label: "Route Code", key: "routeCode" },
  { label: "'Shift Type'", key: "shiftType" },
  { label: "Route Date", key: "dayDeliveryStart" },
  { label: "Starting Delivery Station", key: "from_name" },
  { label: "Start Time", key: "startTime" },
  { label: "Estimated Shift Time(in hours)", key: "estimatedShiftTime" },
  { label: "Estimated Shift Pay", key: "estimatedShiftPayFrom" },
  { label: "Payout", key: "price" },
  { label: "OAI Price", key: "oai_price" },
  { label: "Total Payout", key: "totalPrice" },
  { label: "Driver Name", key: "driverName" },
  { label: "Username", key: "username" },
  { label: "Driver Phone Number", key: "driverPhone" },
  // { label: "Company", key: "company" },
  { label: "Zones/Notes", key: "note" },
  { label: "Internal notes", key: "internalNote" },
  // { label: "Confirm", key: "confirm" },
  { label: "Status", key: "status" },
  { label: "Cancel Status", key: "cancelType" },
];

export function shiftsData(filteredOrdersData) {
  const shiftsData = []; 

  if (filteredOrdersData && filteredOrdersData.length) {
    for(let i = 0;i< filteredOrdersData.length;i++){
      headers.splice(17,18)
      if(filteredOrdersData[i].rejectedDriver){
        filteredOrdersData[i].rejectedDriver ? 
        headers.push(
          { label: "Reject Driver", key: "rejectedDriver" },
          { label: "Reject Reason", key: "rejectedReason" }
        )  : headers.splice(17,18)
        break;
      }
    }
      
    for (let i = 0; i < filteredOrdersData.length; i++) {
      let data = filteredOrdersData[i];

      const {
        metro,
        routeCode,
        shiftType,
        dayDeliveryStart,
        from,
        startTime,
        estimatedShiftTimeFrom,
        estimatedShiftTimeTo,
        estimatedShiftPayFrom,
        estimatedShiftPayTo,
        price,
        OAI_price,
        totalPrice,
        driver,
        note,
        internalNote,
        isConfirmed,
        status,
        cancelType: cancel,
        rejectedDriver,
        rejectedReason,
        isRequestedPayments,
        isWeeklyPayments
      } = data || {};

      const { firstName, lastName, username, phone, company } = driver || {};

      shiftsData[i] = {
        metro: `${metro ? metro : "-"}`,
        routeCode: `${routeCode ? routeCode : "-"}`,
        shiftType: `${shiftType ? shiftType : "-"}`,
        dayDeliveryStart: ` ${dayDeliveryStart
            ? moment(dayDeliveryStart).utc().format("MM-DD-YYYY")
            : "-"
          }`,
        from_name: `${from?.name ? from?.name : "-"}`,
        startTime: `${startTime ? getRoute(startTime) : "-"}`,
        estimatedShiftTime: `${handleEstimatedShiftTime(
          estimatedShiftTimeFrom,
          estimatedShiftTimeTo
        )}`,
        estimatedShiftPayFrom: `${handleEstimatedShiftPay(
          estimatedShiftPayFrom,
          estimatedShiftPayTo
        )}`,
        price: handlePrice(price),
        oai_price: handlePrice(OAI_price),
        totalPrice: handlePrice(totalPrice || price),
        driverName: `${firstName ? firstName : "-"} ${lastName ? lastName : ""
          }`,
        username: `${username ? username : "-"}`,
        driverPhone: `${phone ? phone : "-"}`,
        // company : `${company?.companyCode ? company?.companyCode : ""} ${company?.companyName && company?.companyCode ? "-" : "" } ${ company?.companyName ? company?.companyName : ""}`,
        note: `${note ? note : "-"}`,
        internalNote: `${internalNote ? internalNote : "-"}`,
        // confirm: `${isConfirmed ? 'Confirmed' : "-"}`,
        status: `${status ? `${handleStatus(status)} ${ (statusList.includes(status) && price) ? '- ' + handlePaymentStatus(status, price, isRequestedPayments, isWeeklyPayments) : ''}` : "-"}`,
        cancelType: `${cancel ? cancelType(cancel) : "-"}`,
        ...(rejectedDriver && {rejectedDriver: `${rejectedDriver ? rejectedDriver[0]?.firstName + " " + rejectedDriver[0]?.lastName : '-'}`}),
        ...(rejectedReason && {rejectedReason: `${rejectedReason ? rejectedReason : '-'}`})
      };
    }
  }
  shiftsData.unshift({metro : `This report based on "${timeZone}  UTC ${utc}" Timezone `})
  return shiftsData;
}